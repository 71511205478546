/** @format */

import React, { useState, useEffect } from "react";
import Header from "../Header";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { API_URL } from "../../constants";
import { Link, useLocation } from "react-router-dom";

const InstructorGrades = () => {
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const user_id = searchParams.get("user_id");
	const exam_id = searchParams.get("exam_id");
	const course_id = searchParams.get("course_id");
	const course_name = searchParams.get("course_name");
	const maxScore = searchParams.get("maxScore");

	// const [feedback, setFeedback] = useState("");

	const [grades, setGrades] = useState([]);

	const getGrades = async () => {
		const endpoint = `${API_URL}instructor/grade/get_enrolled_students.php?course_id=${course_id}`;

		try {
			const response = await fetch(endpoint);
			if (response.ok) {
				const data = await response.json();
				console.log("student data is ", data);
				const old_grades = [...data.students];
				for (let i = 0; i < old_grades.length; i++) {
					old_grades[i].instructor_feedback = "";
					old_grades[i].grade = "";
				}
				(async () => {
					const endpoint = `${API_URL}instructor/grade/get_student_grades_by_exam_id.php?exam_id=${exam_id}`;

					try {
						const response = await fetch(endpoint);
						if (response.ok) {
							const data = await response.json();
							// console.log(data);
							console.log("exam data is ", data);

							data.data?.forEach((item) => {
								let student_id = item.student_id;
								for (let i = 0; i < old_grades.length; i++) {
									if (old_grades[i].student_id === student_id) {
										old_grades[i].instructor_feedback =
											item.instructor_feedback;
										old_grades[i].grade = item.grade;
									}
								}
							});
							console.log(old_grades);
							setGrades(old_grades);
						} else {
							console.error("Failed to fetch exams");
						}
					} catch (error) {
						console.error("Error fetching exams:", error);
					}
				})();
			} else {
				console.error("Failed to fetch exams");
			}
		} catch (error) {
			console.error("Error fetching exams:", error);
		}
	};
	const handleChange = (e, studentId, field) => {
		const { value } = e.target;

		const updatedGrades = grades.map((grade) => {
			if (grade.student_id === studentId) {
				return { ...grade, [field]: value };
			}
			return grade;
		});
		setGrades(updatedGrades);
	};

	useEffect(() => {
		getGrades();
	}, []);

	const handleSave = async (grade) => {
		// event.preventDefault();
		console.log(grade);
		const formData = new FormData();
		formData.append("student_id", grade.student_id);
		formData.append("exam_id", exam_id);
		formData.append("grade", grade.grade);
		formData.append("instructor_feedback", grade.instructor_feedback);

		try {
			const response = await fetch(API_URL + "instructor/grade/new_grade.php", {
				method: "POST",
				body: formData,
			});

			if (response.ok) {
				const responseData = await response.json();
				console.log(responseData);
				alert(responseData.message);
				getGrades();
			} else {
				const errorData = await response.text();
				console.error("Error while logging in:", errorData);
				alert("An error occurred while logging in.");
			}
		} catch (error) {
			console.error("Error while logging in:", error);
			alert("An error occurred while logging in.");
		}
	};

	return (
		<>
			<Header role='Instructor' header={[["Grades", ""]]} loggedIn={true} />
			<Navbar nav={[["Home", `/instructor?user_id=${user_id}`]]} />
			<div class='wrapper' style={{ alignItems: "flex-start" }}>
				<div
					class='instructor-container'
					style={{ display: "flex", justifyContent: "space-evenly" }}>
					<div>
						<h2>Course: {course_name}</h2>
						<table className='grade-table'>
							<tr>
								<th>Student Name</th>
								<th>Grades out of {maxScore}</th>
								<th>Comments</th>
								<th>Save</th>
							</tr>

							{grades?.map((grade) => (
								<tr key={grade.student_id}>
									<td>
										{grade.first_name} {grade.last_name}
									</td>
									<td>
										<input
											type='text'
											placeholder='Enter grade'
											defaultValue={grade.grade}
											onChange={(e) =>
												handleChange(e, grade.student_id, "grade")
											}
										/>
									</td>
									<td>
										<input
											type='text'
											placeholder='Enter comment'
											defaultValue={grade.instructor_feedback}
											onChange={(e) =>
												handleChange(e, grade.student_id, "instructor_feedback")
											}
										/>
									</td>
									<td>
										<button onClick={() => handleSave(grade)}>Save</button>
									</td>
								</tr>
							))}
						</table>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default InstructorGrades;
