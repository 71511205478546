/** @format */

import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import "../../App.css";

const Services = () => {
	const header = [
		["Home", "/"],
		["Services", "/services"],
		["Contact Us", "/contact"],
		["About Us", "/about"],
		["Blog", "https://vxy4152.uta.cloud/wp/"],
	];
	return (
		<>
			<Header role='any' header={header} loggedIn={false} />
			<div style={{ height: "85vh" }}>
				<div className='sectionContainer'>
					<div className='sectionIntro'>
						<h3>
							Eduflex is a versatile course management platform designed to
							streamline education management. It provides students with easy
							access to program materials and assessments while supporting
							collaboration with peers and instructors. Administrators ensure
							smooth website operation, and the system maintains rigorous
							academic quality and compliance.
						</h3>
					</div>
				</div>

				<div className='row-container'>
					<div className='row'>
						<div className='column'>
							<div className='card'>
								<div className='home-container'>
									<h2>Student</h2>
									<p>
										Students use the website to access program information,
										course materials, take exams, view results, and interact
										with instructors and peers, engaging in their academic
										journey.
									</p>
									{/* <p>
										<Link to='/student'>
											<button className='button'>Student Profile</button>
										</Link>
									</p> */}
								</div>
							</div>
						</div>

						<div className='column'>
							<div className='card'>
								<div className='home-container'>
									<h2>Instructor</h2>
									<p>
										Instructors teach courses, create content and assessments,
										grade exams, and engage with students to monitor their
										progress and support their learning within the academic
										program. They align course objectives with program goals.
									</p>
									{/* <p>
										<Link to='/instructor'>
											<button className='button'>Instructor Profile</button>
										</Link>
									</p> */}
								</div>
							</div>
						</div>

						<div className='column'>
							<div className='card'>
								<div className='home-container'>
									<h2>Administrator</h2>
									<p>
										Administrators oversee website content, user management, and
										technical maintenance to ensure functionality, security, and
										data analysis.
									</p>
									{/* <p>
										<Link to='/admin'>
											<button className='button'>Admin</button>
										</Link>
									</p> */}
								</div>
							</div>
						</div>
					</div>

					<div className='row'>
						<div className='column'>
							<div className='card'>
								<div className='home-container'>
									<h2>Program Coordinator</h2>
									<p>
										The Program Coordinator oversees and aligns academic
										programs with institution goals, coordinating with
										instructors and stakeholders for effective implementation,
										while also monitoring performance and serving as a student
										point of contact.
									</p>
									{/* <p>
										<Link to='/pc'>
											<button className='button'>Program Coordinator</button>
										</Link>
									</p> */}
								</div>
							</div>
						</div>

						<div className='column'>
							<div className='card'>
								<div className='home-container'>
									<h2>Quality Assurance Officer</h2>
									<p>
										A Quality Assurance Officer ensures academic program
										quality, assesses alignment with standards, and collaborates
										for improvements while maintaining regulatory compliance.
									</p>
									{/* <p>
										<Link to='/qa'>
											<button className='button'>
												Quality Assurance Officer
											</button>
										</Link>
									</p> */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default Services;
