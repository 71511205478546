/** @format */

import React from "react";
import Header from "../Header";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { Link, useLocation } from "react-router-dom";

const ProgramCoordinator = () => {
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const user_id = searchParams.get("user_id");
	return (
		<>
			<Header
				role='PC'
				header={[["Program Coordinator Dashboard", ""]]}
				loggedIn={true}
			/>
			<Navbar
				nav={[
					["Home", `/pc?user_id=${user_id}`],
					["Profile", `/pc/profile?user_id=${user_id}`],
					["Messages", `/pc/messages?user_id=${user_id}`],
				]}
			/>
			<div className='home-wrapper'>
				<div className='container'>
					<div className='container-title'>Collaborate</div>
					<div className='button-container'>
						<button className='main-button'>
							<Link to={`/pc/messages?user_id=${user_id}`}>
								Connect with Instructors
							</Link>
						</button>
						<button className='main-button'>
							<Link to={`/pc/messages?user_id=${user_id}`}>
								Connect with Students
							</Link>
						</button>
						<button className='main-button'>
							<Link to={`/pc/messages?user_id=${user_id}`}>
								Connect with Administrator
							</Link>
						</button>
					</div>
				</div>
				<div className='container'>
					<div className='container-title'>Monitor</div>
					<div className='button-container'>
						<button className='main-button'>
							<Link to={`/pc/messages?user_id=${user_id}`}>Requests</Link>
						</button>
						<button className='main-button'>
							<Link to={`/pc/performance?user_id=${user_id}`}>
								Performance Monitoring
							</Link>
						</button>
					</div>
				</div>
				<div className='container'>
					<div className='container-title'>Program Policies</div>
					<div className='button-container'>
						<button className='main-button'>
							<Link
								to={`/pc/createPolicy?user_id=${user_id}`}
								state={{ role: "PC" }}>
								Create New Policy
							</Link>
						</button>
						<button className='main-button'>
							<Link
								to={`/pc/policies?user_id=${user_id}`}
								state={{ role: "PC" }}>
								View Policies
							</Link>
						</button>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default ProgramCoordinator;
