/** @format */

import React, { useState, useEffect } from "react";
import Header from "../Header";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { Link, useLocation } from "react-router-dom";

const Policies = () => {
	const location = useLocation();
	const role = location?.state?.role;
	const searchParams = new URLSearchParams(location.search);
	const user_id = searchParams.get("user_id");
	const [policies, setPolicies] = useState([]);

	useEffect(() => {
		// Make a GET request to fetch policies based on user_id
		fetch(
			`http://localhost/test/backend/qa/get_policies.php?user_id=${user_id}`
		)
			.then((response) => response.json())
			.then((data) => {
				if (data.success) {
					setPolicies(data.policies);
				} else {
					alert("Failed to fetch policies. Please try again.");
				}
			})
			.catch((error) => {
				console.error("Error while fetching policies:", error);
				alert("An error occurred while fetching policies.");
			});
	}, [user_id]);

	return (
		<>
			<Header role={role} header={[["Dashboard", ""]]} loggedIn={true} />
			<Navbar
				nav={[["Dashboard", `/${role.toLowerCase()}?user_id=${user_id}`]]}
			/>
			<div
				style={{
					backgroundColor: "#c3e9ce",
					width: "100%",
					textAlign: "center",
					padding: "10px 0px 20px 0px",
				}}>
				<Link
					to={`/${role.toLowerCase()}/createPolicy?user_id=${user_id}`}
					state={{
						role: role,
					}}>
					<button style={{ backgroundColor: "#982652" }} className='btn'>
						Create Policy
					</button>
				</Link>
			</div>

			<div className='wrapper' style={{ alignItems: "flex-start" }}>
				<div
					className='qa-container'
					style={{ width: "50%", alignItems: "center" }}>
					<table style={{ border: "1px" }}>
						<thead>
							<tr>
								<th>Policy ID</th>
								<th>Policy Title</th>
								<th>Course ID</th>
								<th>Description</th>
								<th>Edit</th>
								<th>Delete</th>
							</tr>
						</thead>
						<tbody>
							{policies.map((policy) => (
								<tr key={policy.id}>
									<td>{policy.id}</td>

									<td>{policy.title}</td>

									<td>{policy.course_id}</td>
									<td>{policy.description}</td>
									<td>
										<Link
											to={`/${role.toLowerCase()}/updatePolicy?user_id=${user_id}&policy_id=${
												policy.id
											}&policy_title=${policy.title}&description=${
												policy.description
											}&course_id=${policy.course_id}`}
											state={{ role: role }}>
											<button className='btn'>Edit</button>
										</Link>
									</td>
									<td>
										<Link
											to={`/${role.toLowerCase()}/deleted`}
											state={{
												role: "Policy",
												nav: [
													[
														"Dashboard",
														`/${role.toLowerCase()}?user_id=${user_id}`,
													],
												],
												props: [policy.id],
											}}>
											<button className='btn'>Delete</button>
										</Link>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default Policies;
