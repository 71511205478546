/** @format */

import React, { useState, useEffect } from "react";
import Header from "../Header";
import Navbar from "../Navbar";
import "../../App.css";
import { API_URL } from "../../constants";
import Footer from "../Footer";
import { Link, useLocation } from "react-router-dom";

const InstructorCourses = () => {
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const user_id = searchParams.get("user_id");
	const course_id = searchParams.get("course_id");
	const course_name = searchParams.get("course_name");

	const [exams, setExams] = useState([]);

	// Function to make the API call
	const getExams = async () => {
		const endpoint = `${API_URL}instructor/exam/get_exams.php?course_id=${course_id}`;

		try {
			const response = await fetch(endpoint);
			if (response.ok) {
				const data = await response.json();
				// console.log("exam data is ", data);
				setExams(data); // Assuming the response is an array of exams, update the state with the data
			} else {
				console.error("Failed to fetch exams");
			}
		} catch (error) {
			console.error("Error fetching exams:", error);
		}
	};

	useEffect(() => {
		getExams();
	}, []);

	const nav = [
		["Home", `/instructor?user_id=${user_id}`],
		["Profile", "/instructor/profile"],

		["Messages", `/instructor/messages?user_id=${user_id}`],
		["Student Activity", `/instructor/performance?user_id=${user_id}`],
	];
	return (
		<>
			<Header role='Instructor' header={[["Courses", ""]]} loggedIn={true} />
			<Navbar nav={nav} />
			<div
				style={{
					backgroundColor: "#c3e9ce",
					width: "100%",
					textAlign: "center",
					padding: "10px 0px 20px 0px",
				}}>
				<Link
					to={`/instructor/courseForm?user_id=${user_id}`}
					state={{
						action: "Create",
					}}>
					<button style={{ backgroundColor: "#982652" }} className='btn'>
						Create Course
					</button>
				</Link>
			</div>
			<div class='wrapper' style={{ alignItems: "flex-start" }}>
				<div class='exam-container'>
					<h1>Grade Assignments/Exams</h1>
					{exams?.exams?.map((exam) => (
						<div
							key={exam.id}
							className='graded-item'
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "space-around",
								gap: "30px",
							}}>
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									gap: "10px",
								}}>
								<div style={{ fontSize: "20px" }}>{exam.exam_name}</div>
								<div>{exam.schedule.replace("T", " , ")}</div>
							</div>

							<div>
								<Link
									to={`/instructor/grades?user_id=${user_id}&exam_id=${exam.id}&course_id=${course_id}&course_name=${course_name}&maxScore=${exam.max_score}`}>
									<button
										style={{ backgroundColor: "#982652" }}
										className='btn'>
										Grade
									</button>
								</Link>

								<Link
									to={`/instructor/createExam?course_id=${course_id}&user_id=${user_id}&exam_id=${exam.id}`}
									state={{
										title: "Update Exam",
										nav: nav,
										action: "Update",
										exam_name: exam.exam_name,
										exam_schedule: exam.schedule,
										exam_score: exam.max_score,
									}}>
									<button
										style={{ backgroundColor: "#982652" }}
										className='btn'>
										Update Exam
									</button>
								</Link>
								<Link
									to={`/instructor/deleted?user_id=${user_id}`}
									state={{
										role: "Exam",
										nav: [["Dashboard", `/instructor?user_id=${user_id}`]],
										props: [exam.id],
									}}>
									<button
										style={{ backgroundColor: "#982652" }}
										className='btn'>
										Delete Exam
									</button>
								</Link>
							</div>
						</div>
					))}
					{/* <div class='graded-item'>
						<p>
							Grade <Link to='/instructor/grades'> Midterm Exam</Link>
						</p>
					</div> */}
					<br />

					{/* <h2>Upcoming Exams</h2>
					{exams?.exams?.map((exam) => (
						<div key={exam.id}>{exam.exam_name}</div>
					))} */}
					<div class='upcoming-exam'>
						<p>
							Click{" "}
							<Link
								to={`/instructor/createExam?user_id=${user_id}&course_id=${course_id}`}
								state={{ title: "Create Exam", nav: nav }}>
								here
							</Link>{" "}
							to create New Exams.
						</p>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default InstructorCourses;
