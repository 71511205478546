/** @format */

import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../Footer";
import Header from "../Header";
import Navbar from "../Navbar";
import { API_URL } from "../../constants";

const InstructorActionForm = (props) => {
	const navigate = useNavigate();
	const location = useLocation();
	const action = location?.state?.action;
	const searchParams = new URLSearchParams(location.search);
	const user_id = searchParams.get("user_id");
	const course_id = searchParams.get("course_id");
	const exam_id = searchParams.get("exam_id");
	// const course_name = searchParams.get("course_name");
	const nav = location?.state?.nav;
	const title = location?.state?.title;
	let exam_name = "";
	let exam_schedule = "";
	let exam_score = "";
	if (action === "Update") {
		exam_name = location?.state?.exam_name;
		exam_schedule = location?.state?.exam_schedule;
		exam_score = location?.state?.exam_score;
	}

	const [examName, setExamName] = useState(exam_name);
	const [examSchedule, setExamSchedule] = useState(exam_schedule);
	const [maxScore, setMaxScore] = useState(exam_score);

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!examName || !maxScore || !examSchedule) {
			alert("All fields are required.");
		} else {
			if (action === "Create") {
				const formData = new FormData();
				formData.append("user_id", user_id);
				formData.append("schedule", examSchedule);
				formData.append("max_score", maxScore);
				formData.append("exam_name", examName);
				formData.append("course_id", course_id);

				try {
					const response = await fetch(
						API_URL + "instructor/exam/new_exam.php",
						{
							method: "POST",
							body: formData,
						}
					);

					if (response.ok) {
						alert("Exam created successfully");
						navigate(`/instructor?user_id=${user_id}`);
					} else {
						alert("Failed to create exam. Please try again.");
					}
				} catch (error) {
					console.error("Error while creating exam:", error);
					alert("An error occurred while creating the exam.");
				}
			} else {
				console.log("in update exam");
				const body = new FormData();
				body.append("exam_id", exam_id);
				body.append("user_id", user_id);
				body.append("exam_name", examName);
				body.append("schedule", examSchedule);
				body.append("max_score", maxScore);

				console.log(exam_id, user_id, examName, examSchedule, maxScore);

				try {
					const response = await fetch(
						`${API_URL}instructor/exam/update_exam.php`,
						{
							method: "POST",
							body: body,
						}
					);

					if (response.ok) {
						response.json().then((a) => {
							console.log(a);
							alert(a.message);
						});
						navigate(`/instructor/?user_id=${user_id}`);
					} else {
						alert("Failed to update the exam. Please try again later.");
					}
				} catch (error) {
					console.error("API Error:", error);
					alert("An error occurred. Please try again later.");
				}
			}
		}
	};

	return (
		<>
			<Header
				role='Instructor'
				header={[["Action Form", ""]]}
				loggedIn={true}
			/>
			<Navbar nav={nav} />
			<div className='wrapper'>
				<div className='container'>
					<h2 style={{ textAlign: "center" }}>{title}</h2>
					<form className='form' onSubmit={handleSubmit}>
						<div className='form-group'>
							<label htmlFor='examName'>Exam Name:</label>
							<input
								type='text'
								id='examName'
								name='examName'
								value={examName}
								onChange={(e) => setExamName(e.target.value)}
								required
							/>
						</div>

						<div className='form-group'>
							<label htmlFor='examSchedule'>Exam Schedule:</label>
							<input
								type='datetime-local'
								id='examSchedule'
								name='examSchedule'
								value={examSchedule}
								onChange={(e) => setExamSchedule(e.target.value)}
								required
							/>
						</div>

						<div className='form-group'>
							<label htmlFor='maxScore'>Max Score:</label>
							<input
								type='text'
								id='maxScore'
								name='maxScore'
								value={maxScore}
								onChange={(e) => setMaxScore(e.target.value)}
								required
							/>
						</div>
						<div className='btn-container'>
							<button type='submit' className='btn'>
								{action} Exam
							</button>
						</div>
					</form>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default InstructorActionForm;
