/** @format */

import React from "react";
import Header from "../Header";
import Navbar from "../Navbar";
import Footer from "../Footer";

const CheckRecommendations = () => {
	return (
		<>
			<Header role='QA' header={[["Check Feedback", ""]]} loggedIn={true} />
			<Navbar
				nav={[
					["Home", "/qa"],
					["Profile", "/qa/profile"],
					["Messages", "/qa/messages"],
				]}
			/>
			<div className='wrapper'>
				<div class='qa-container'>
					<h1 style={{ "text-align": "center" }}>Check Feedback</h1>
					<table border='1'>
						<thead>
							<tr>
								<th>Title</th>
								<th>Date Created</th>
								<th>Course</th>
								<th>Description</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Recommendation 1</td>
								<td>2023-09-20</td>
								<td>Course A</td>
								<td>This is the description for Feedback1.</td>
							</tr>
							<tr>
								<td>Recommendation 2</td>
								<td>2023-09-21</td>
								<td>Course B</td>
								<td>This is the description for Feedback2.</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default CheckRecommendations;
