/** @format */

import React from "react";
import { Link } from "react-router-dom";

const Navbar = ({ nav }) => {
	return (
		<>
			<nav className='navBarMenu'>
				<ul className='navList'>
					{nav.map(([navItem, navLink]) => (
						<React.Fragment key={navItem}>
							{navItem === "Blog" ? (
								<a href={navLink} target='_blank' rel='noopener noreferrer'>
									<li>{navItem}</li>
								</a>
							) : (
								<Link
									key={navItem}
									to={navLink}
									style={{ textDecoration: "none" }}>
									<li>{navItem}</li>
								</Link>
							)}
						</React.Fragment>
					))}
				</ul>
			</nav>
		</>
	);
};

export default Navbar;
