/** @format */

import React, { useEffect, useState } from "react";
import Header from "../Header";
import Navbar from "../Navbar";
import { API_URL } from "../../constants";
import Footer from "../Footer";
import { Link, useLocation } from "react-router-dom";

const StudentCourses = () => {
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const user_id = searchParams.get("user_id");
	const [courses, setCourses] = useState([]);

	const handleEnroll = async (course_id) => {
		// event.preventDefault();

		const formData = new FormData();
		formData.append("student_id", user_id);
		formData.append("course_id", course_id);

		try {
			const response = await fetch(
				API_URL + "student/enroll/new_enrollment.php",
				{
					method: "POST",
					body: formData,
				}
			);

			if (response.ok) {
				const responseData = await response.json();
				console.log(responseData);
				alert("Enrollment successful!");
			} else {
				const errorData = await response.text();
				console.error("Error while logging in:", errorData);
				alert("An error occurred while logging in.");
			}
		} catch (error) {
			console.error("Error while logging in:", error);
			alert("An error occurred while logging in.");
		}
	};
	useEffect(() => {
		const fetchCourses = async () => {
			try {
				const response = await fetch(
					`${API_URL}course/get_available_courses.php?student_id=${user_id}`
				);

				if (response.ok) {
					const data = await response.json();
					// console.log(data);
					setCourses(data.courses_with_status);
				} else {
					console.error("Failed to fetch courses.");
				}
			} catch (error) {
				console.error("Error while fetching courses:", error);
			}
		};

		fetchCourses();
	}, [courses]);
	return (
		<>
			<Header role='Student' header={[["Courses", ""]]} loggedIn={true} />
			<Navbar
				nav={[
					["Home", `/student?user_id=${user_id}`],
					["Profile", `/student/profile?user_id=${user_id}`],
					["My Courses", `/student/courses?user_id=${user_id}`],
					["Messages", `/student/messages?user_id=${user_id}`],
				]}
			/>

			<div className='wrapper' style={{ width: "auto" }}>
				<div className='container' style={{ width: "auto" }}>
					<h2>Enrolled Courses</h2>
					{courses?.map((course) => (
						<div key={course.id} className='course-card-container'>
							<Link
								to={`/student/courseDetails?user_id=${user_id}&course_id=${course.id}&course_name=${course.name}`}
								state={{ course_content: course.course_content }}
								className='course-card'>
								<h3 className='course-title'>{course.name}</h3>
								<p className='course-details'>Course Code: {course.code}</p>
								{/* <p className='course-details'>Instructor: John Doe</p> */}
								<p className='course-details'>Schedule: {course.schedule}</p>
								{course.enrolled === 0 ? (
									<button id='enroll' onClick={() => handleEnroll(course.id)}>
										Enroll
									</button>
								) : null}
							</Link>
						</div>
					))}
				</div>
			</div>
			<Footer />
		</>
	);
};

export default StudentCourses;
