/** @format */

import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Footer from "../Footer";
import Header from "../Header";
import Navbar from "../Navbar";
import { API_URL } from "../../constants";

const CourseForm = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const user_id = searchParams.get("user_id");
	const action = location?.state?.action;
	const [courseID, setCourseID] = useState("");
	const [courseSchedule, setCourseSchedule] = useState("");
	const [courseName, setCourseName] = useState("");
	const [courseContent, setCourseContent] = useState("");

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!courseID || !courseName || !courseSchedule || !courseContent) {
			alert("All fields are required. Please fill in all fields.");
		} else {
			const formData = new FormData();
			formData.append("name", courseName);
			formData.append("code", courseID);
			formData.append("schedule", courseSchedule);
			formData.append("course_content", courseContent);
			formData.append("user_id", user_id);

			try {
				const response = await fetch(
					API_URL + "instructor/course/new_course.php",
					{
						method: "POST",
						body: formData,
					}
				);

				if (response.ok) {
					alert("Course created successfully");
					navigate(`/instructor?user_id=${user_id}`);
				} else {
					alert("Failed to create course. Please try again.");
				}
			} catch (error) {
				console.error("Error while creating course:", error);
				alert("An error occurred while creating the course.");
			}
		}
	};

	return (
		<>
			<Header
				role='Instructor'
				header={[["Instructor Dashboard", ""]]}
				loggedIn={true}
			/>
			<Navbar nav={[["Home", `/instructor?user_id=${user_id}`]]} />
			<div className='wrapper'>
				<div className='container'>
					<h2 style={{ textAlign: "center" }}>{action} Course</h2>
					<form className='form' onSubmit={handleSubmit}>
						<div className='form-group'>
							<label htmlFor='courseName'>Course Name:</label>
							<input
								type='text'
								id='courseName'
								name='courseName'
								required
								value={courseName}
								onChange={(e) => setCourseName(e.target.value)}
							/>
						</div>

						<div className='form-group'>
							<label htmlFor='courseID'>Course Code:</label>
							<input
								type='text'
								id='courseID'
								name='courseID'
								required
								value={courseID}
								onChange={(e) => setCourseID(e.target.value)}
							/>
						</div>
						<div className='form-group'>
							<label htmlFor='courseSchedule'>Course Schedule:</label>
							<input
								type='text'
								id='courseSchedule'
								name='courseSchedule'
								required
								value={courseSchedule}
								onChange={(e) => setCourseSchedule(e.target.value)}
							/>
						</div>
						<div className='form-group'>
							<label htmlFor='courseContent'>Course Content:</label>
							<textarea
								type='text'
								id='courseContent'
								name='courseContent'
								rows='4'
								required
								value={courseContent}
								onChange={(e) => setCourseContent(e.target.value)}
							/>
						</div>
						<div className='btn-container'>
							<button className='btn' type='submit'>
								{action} Course
							</button>
						</div>
					</form>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default CourseForm;
