/** @format */

import React, { useState, useEffect } from "react";
import Header from "./Header";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { API_URL } from "../constants";

const Profile = ({ role }) => {
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const user_id = searchParams.get("user_id");

	const [profile, setProfile] = useState(null);

	useEffect(() => {
		// Function to make the API call
		const getProfile = async () => {
			const endpoint = `${API_URL}profile/get_profile.php?user_id=${user_id}`;

			try {
				const response = await fetch(endpoint);
				if (response.ok) {
					const data = await response.json();
					setProfile(data.user);
				} else {
					console.error("Failed to fetch profile");
				}
			} catch (error) {
				console.error("Error fetching profile:", error);
			}
		};

		getProfile();
	}, [user_id]);

	return (
		<>
			<Header role={role} header={[["User Profile", ""]]} loggedIn={true} />
			<Navbar
				nav={[
					["Home", `/${role.toLowerCase()}?user_id=${user_id}`],
					["Profile", `/${role.toLowerCase()}/profile?user_id=${user_id}`],

					["Messages", `/${role.toLowerCase()}/messages?user_id=${user_id}`],
					["Report", `/${role.toLowerCase()}/performance?user_id=${user_id}`],
				]}
			/>

			<div className='wrapper'>
				<div className='profile-container'>
					<h1>Your Profile</h1>
					{profile && (
						<table>
							<tbody>
								<tr>
									<th>Name:</th>
									<td>{`${profile.first_name} ${profile.last_name}`}</td>
								</tr>
								<tr>
									<th>Email:</th>
									<td>{profile.email}</td>
								</tr>
								<tr>
									<th>User ID:</th>
									<td>{profile.id}</td>
								</tr>
								<tr>
									<th>Contact:</th>
									<td>{profile.phone}</td>
								</tr>
								<tr>
									<td colSpan={2}>
										<div style={{ padding: "10px", textAlign: "center" }}>
											<Link
												to={`/${role.toLowerCase()}/editProfile?user_id=${user_id}`}>
												<button className='btn'>Update Profile</button>
											</Link>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					)}
				</div>
			</div>
			<Footer />
		</>
	);
};

export default Profile;
