/** @format */

import React, { useState, useEffect } from "react";
import Footer from "../Footer";
import Header from "../Header";
import Navbar from "../Navbar";
import { API_URL } from "../../constants";
import { Link, useLocation, useNavigate } from "react-router-dom";

const ManageQA = (props) => {
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const user_id = searchParams.get("user_id");
	const nav = [["Dashboard", `/admin?user_id=${user_id}`]];
	const [qas, setQas] = useState([]);

	useEffect(() => {
		fetch(API_URL + "admin/qao/get_qaos.php")
			.then((response) => response.json())
			.then((data) => {
				if (data.success) {
					setQas(data.users);
				} else {
					console.error("Failed to fetch QA data.");
				}
			});
	}, []);

	return (
		<>
			<Header
				role='Admin'
				header={[["Admin Management Console", ""]]}
				loggedIn={true}
			/>
			<Navbar nav={nav} />

			<div class='textBox'>
				<p class='text-heading'>Quality Assurance Officer</p>
			</div>
			<div
				style={{
					backgroundColor: "#c3e9ce",
					width: "100%",
					textAlign: "center",
				}}>
				<Link
					to={`/admin/actionForm?user_id=${user_id}`}
					state={{
						role: "QA",
						nav: [
							["Home", `/admin?user_id=${user_id}`],
							["Manage QAs", `/admin/manageQA?user_id=${user_id}`],
						],
						title: "Add New QA",
						action: "Add",
					}}>
					<button className='btn' style={{ backgroundColor: "#982652" }}>
						Add Quality Assurance Officer
					</button>
				</Link>
			</div>

			<div class='list-wrapper'>
				<table>
					<thead>
						<tr>
							<th>Name</th>
							<th>Employee ID</th>
							<th>Email Address</th>
							<th>Phone Number</th>
							<th>Edit</th>
							<th>Delete</th>
						</tr>
					</thead>
					<tbody>
						{qas.map((qa) => (
							<tr key={qa.id}>
								<td>{`${qa.first_name} ${qa.last_name}`}</td>
								<td>{qa.id}</td>
								<td>{qa.email}</td>
								<td>{qa.phone}</td>

								<td>
									<Link
										to={`/admin/editAction?user_id=${user_id}&edit_id=${qa.id}`}
										state={{
											role: "QA",
											nav: [
												["Home", `/admin?user_id=${user_id}`],
												["Manage QAs", `/admin/manageQA?user_id=${user_id}`],
											],
											title: "Change QAO Details",
											action: "Save",
											first_name: qa.first_name,
											last_name: qa.last_name,
											email: qa.email,
											contact: qa.phone,
										}}>
										<button
											className='btn'
											style={{ backgroundColor: "#982652" }}>
											Edit
										</button>
									</Link>
								</td>
								<td>
									<Link
										to='/admin/deleted'
										state={{
											role: "QA",
											nav: [["Dashboard", `/admin?user_id=${user_id}`]],
											props: [qa.id],
										}}>
										<button
											className='btn'
											style={{ backgroundColor: "#982652" }}>
											Delete
										</button>
									</Link>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
			<Footer />
		</>
	);
};

export default ManageQA;
