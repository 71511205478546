/** @format */

import React, { useState, useEffect } from "react";
import Footer from "../Footer";
import Header from "../Header";
import Navbar from "../Navbar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { API_URL } from "../../constants";

const ManageInstructors = (props) => {
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const user_id = searchParams.get("user_id");
	const nav = [["Dashboard", `/admin?user_id=${user_id}`]];

	// State to store the instructor data
	const [instructors, setInstructors] = useState([]);

	// Function to make the GET API call to retrieve instructor data
	const fetchInstructors = async () => {
		try {
			const response = await fetch(
				`${API_URL}/admin/instructor/get_instructors.php`
			);
			if (response.ok) {
				const data = await response.json();
				if (data.success) {
					setInstructors(data.users);
				} else {
					console.error("Failed to fetch instructors");
				}
			} else {
				console.error("Failed to fetch instructors");
			}
		} catch (error) {
			console.error("Error fetching instructors:", error);
		}
	};

	useEffect(() => {
		fetchInstructors(); // Fetch instructors when the component mounts
	}, [instructors]);

	return (
		<>
			<Header
				role='Admin'
				header={[["Admin Management Console", ""]]}
				loggedIn={true}
			/>
			<Navbar nav={nav} />

			<div className='textBox'>
				<p className='text-heading'>Instructors</p>
			</div>
			<div
				style={{
					backgroundColor: "#c3e9ce",
					width: "100%",
					textAlign: "center",
				}}>
				<Link
					to={`/admin/actionForm?user_id=${user_id}`}
					state={{
						role: "Instructor",
						nav: [
							["Home", `/admin?user_id=${user_id}`],
							[
								"Manage Instructors",
								`/admin/manageInstructors?user_id=${user_id}`,
							],
						],
						title: "Add New Instructor",
						action: "Add",
					}}>
					<button className='btn' style={{ backgroundColor: "#982652" }}>
						Add Instructor
					</button>
				</Link>
			</div>

			<div className='list-wrapper'>
				<table>
					<thead>
						<tr>
							<th>Name</th>
							<th>Employee ID</th>
							<th>Email Address</th>
							<th>Phone Number</th>

							<th>Edit</th>
							<th>Delete</th>
						</tr>
					</thead>
					<tbody>
						{instructors.map((instructor) => (
							<tr key={instructor.id}>
								<td>{`${instructor.first_name} ${instructor.last_name}`}</td>
								<td>{instructor.id}</td>
								<td>{instructor.email}</td>
								<td>{instructor.phone}</td>

								<td>
									<Link
										to={`/admin/editAction?user_id=${user_id}&edit_id=${instructor.id}`}
										state={{
											role: "Instructor",
											nav: [
												["Home", `/admin?user_id=${user_id}`],
												[
													"Manage Instructors",
													`/admin/manageInstructors?user_id=${user_id}`,
												],
											],
											title: "Change Instructor Details",
											action: "Save",
											first_name: instructor.first_name,
											last_name: instructor.last_name,
											email: instructor.email,
											contact: instructor.phone,
										}}>
										<button
											className='btn'
											style={{ backgroundColor: "#982652" }}>
											Edit
										</button>
									</Link>
								</td>
								<td>
									<Link
										to='/admin/deleted'
										state={{
											role: "Instructor",
											nav: [["Dashboard", `/admin?user_id=${user_id}`]],
											props: [instructor.id],
										}}>
										<button
											className='btn'
											style={{ backgroundColor: "#982652" }}>
											Delete
										</button>
									</Link>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
			<Footer />
		</>
	);
};

export default ManageInstructors;
