/** @format */

import React, { useState, useEffect } from "react";
import Footer from "../Footer";
import Header from "../Header";
import Navbar from "../Navbar";
import { Link, useLocation } from "react-router-dom";
import { API_URL } from "../../constants"; // Import your API URL

const ManageStudents = (props) => {
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const user_id = searchParams.get("user_id");
	const [students, setStudents] = useState([]);

	useEffect(() => {
		// Fetch student data from the API
		fetch(API_URL + "admin/get_students.php?role_id=1")
			.then((response) => response.json())
			.then((data) => {
				if (data.success) {
					setStudents(data.users);
				} else {
					console.error("Failed to fetch student data.");
				}
			});
	}, [students]);

	const nav = [["Dashboard", `/admin?user_id=${user_id}`]];

	return (
		<>
			<Header
				role='Admin'
				header={[["Admin Management Console", ""]]}
				loggedIn={true}
			/>
			<Navbar nav={nav} />

			<div className='textBox'>
				<p className='text-heading'>Students</p>
			</div>
			<div
				style={{
					backgroundColor: "#c3e9ce",
					width: "100%",
					textAlign: "center",
				}}>
				<Link
					to={`/admin/actionForm?user_id=${user_id}`}
					state={{
						role: "Student",
						nav: [
							["Home", `/admin?user_id=${user_id}`],
							["Manage Students", `/admin/manageStudents?user_id=${user_id}`],
						],
						title: "Add New Student",
						action: "Add",
					}}>
					<button className='btn' style={{ backgroundColor: "#982652" }}>
						Add Student
					</button>
				</Link>
			</div>

			<div className='list-wrapper'>
				<table id='manage-students-table'>
					<thead>
						<tr>
							<th>Student ID</th>
							<th>Name</th>
							<th>Email Address</th>
							<th>Phone Number</th>
							<th>Edit</th>
							<th>Delete</th>
						</tr>
					</thead>
					<tbody>
						{students.map((student) => (
							<tr key={student.id}>
								<td>{student.id}</td>
								<td>
									{student.first_name} {student.last_name}
								</td>

								<td>{student.email}</td>
								<td>{student.phone}</td>
								<td>
									<Link
										to={`/admin/editAction?user_id=${user_id}&edit_id=${student.id}`}
										state={{
											role: "Student",
											nav: [
												["Home", `/admin?user_id=${user_id}`],
												[
													"Manage Students",
													`/admin/manageStudents?user_id=${user_id}`,
												],
											],
											title: "Change Student Details",
											action: "Save",
											first_name: student.first_name,
											last_name: student.last_name,
											email: student.email,
											contact: student.phone,
										}}>
										<button
											className='btn'
											style={{ backgroundColor: "#982652" }}>
											Edit
										</button>
									</Link>
								</td>
								<td>
									<Link
										to='/admin/deleted'
										state={{
											role: "Student",
											nav: [["Dashboard", `/admin?user_id=${user_id}`]],
											props: [student.id],
										}}>
										<button
											className='btn'
											style={{ backgroundColor: "#982652" }}>
											Delete
										</button>
									</Link>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
			<Footer />
		</>
	);
};

export default ManageStudents;
