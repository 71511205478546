/** @format */

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Footer from "./Footer";
import { API_URL } from "../constants";
import Header from "./Header";
import Navbar from "./Navbar";

const Deleted = () => {
	const location = useLocation();
	const role = location?.state?.role;
	const nav = location?.state?.nav;
	const props = location?.state?.props;
	const [result, setResult] = useState("");

	useEffect(() => {
		if (role === "Student") {
			const studentId = props[0];
			const formData = new FormData();
			formData.append("user_id", studentId);

			fetch(API_URL + "admin/delete_student.php", {
				method: "POST",
				body: formData,
			})
				.then((response) => {
					if (response.ok) {
						console.log(
							response.json().then((a) => {
								setResult(a.message);
							})
						);
					} else {
						alert("Failed to delete student.");
					}
				})

				.catch((error) => {
					console.error("Error while deleting student:", error);
				});
		} else if (role === "Instructor") {
			const instructorId = props[0];
			console.log("instructor id is ", instructorId);
			const formData = new FormData();
			formData.append("user_id", instructorId);

			fetch(API_URL + "admin/instructor/delete_instructor.php", {
				method: "POST",
				body: formData,
			})
				.then((response) => {
					if (response.ok) {
						//alert("Instructor deleted successfully.");

						console.log(
							response.json().then((a) => {
								setResult(a.message);
							})
						);
					} else {
						alert("Failed to delete instructor.");
					}
				})
				.catch((error) => {
					console.error("Error while deleting instructor:", error);
				});
		} else if (role === "QA") {
			const qaId = props[0];
			const formData = new FormData();
			formData.append("user_id", qaId);

			fetch(API_URL + "admin/qao/delete_qao.php", {
				method: "POST",
				body: formData,
			})
				.then((response) => {
					if (response.ok) {
						console.log(
							response.json().then((a) => {
								setResult(a.message);
							})
						);
					} else {
						alert("Failed to delete QAO.");
					}
				})
				.catch((error) => {
					console.error("Error while deleting QAO:", error);
				});
		} else if (role === "PC") {
			const pcId = props[0];
			const formData = new FormData();
			formData.append("user_id", pcId);

			fetch(API_URL + "admin/pc/delete_pc.php", {
				method: "POST",
				body: formData,
			})
				.then((response) => {
					if (response.ok) {
						console.log(
							response.json().then((a) => {
								setResult(a.message);
							})
						);
					} else {
						alert("Failed to delete Program Coordinator.");
					}
				})
				.catch((error) => {
					console.error("Error while deleting Program Coordinator:", error);
				});
		} else if (role === "Policy") {
			const policyId = props[0];
			const formData = new FormData();
			formData.append("policy_id", policyId);

			fetch(API_URL + "qa/delete_policy.php", {
				method: "POST",
				body: formData,
			})
				.then((response) => {
					if (response.ok) {
						response.json().then((a) => {
							setResult(a.message);
						});
					} else {
						alert("Failed to delete policy.");
					}
				})
				.then((result) => {
					setResult(result);
				})
				.catch((error) => {
					console.error("Error while deleting policy:", error);
				});
		} else if (role === "Exam") {
			const exam_id = props[0];
			const formData = new FormData();
			formData.append("exam_id", exam_id);

			fetch(API_URL + "instructor/exam/delete_exam.php", {
				method: "POST",
				body: formData,
			})
				.then((response) => {
					if (response.ok) {
						console.log(
							response.json().then((a) => {
								setResult(a.message);
							})
						);
					} else {
						alert("Failed to delete exam.");
					}
				})
				.catch((error) => {
					console.error("Error while deleting exam:", error);
				});
		} else if (role === "Course") {
			const course_id = props[0];
			const formData = new FormData();
			formData.append("course_id", course_id);

			fetch(API_URL + "instructor/course/delete_course.php", {
				method: "POST",
				body: formData,
			})
				.then((response) => {
					if (response.ok) {
						response.json().then((a) => {
							setResult(a.message);
						});
					} else {
						alert("Failed to delete Course.");
					}
				})
				.then((result) => {
					setResult(result);
				})
				.catch((error) => {
					console.error("Error while deleting Course:", error);
				});
		}
	}, [role, props]);

	return (
		<>
			<Header
				role={role}
				header={[[role + " Management Console", ""]]}
				loggedIn={true}
			/>
			<Navbar nav={nav} />

			<div className='wrapper'>
				{/* <h2>{role === "PC" ? "Program Coordinator" : role} Deleted!</h2> */}
				<div style={{ fontWeight: "bold" }}>{result}</div>
			</div>
			<Footer />
		</>
	);
};

export default Deleted;
