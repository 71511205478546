/** @format */

import React, { useState } from "react";
import Footer from "../Footer";
import Header from "../Header";
import Navbar from "../Navbar";

const GenerateReports = () => {
	const [selectedCourse, setSelectedCourse] = useState("");
	const [selectedExam, setSelectedExam] = useState("");

	const handleGenerateReport = (e) => {
		e.preventDefault();

		if (!selectedCourse || !selectedExam) {
			alert("All fields are required.");
		} else {
			alert("Form submitted successfully");
		}
	};

	return (
		<>
			<Header
				role='Admin'
				header={[["Generate Reports", ""]]}
				loggedIn={true}
			/>
			<Navbar
				nav={[
					["Home", "/admin"],
					["Profile", "/admin/profile"],
					["Messages", "/admin/messages"],
					["Reports", "/admin/performance"],
				]}
			/>
			<div className='wrapper'>
				<div className='course-card'>
					<h2>Reports</h2>
					<form onSubmit={handleGenerateReport}>
						<div>
							<label htmlFor='selectCourse'>Select Course:</label>
							<select
								id='selectCourse'
								name='selectCourse'
								value={selectedCourse}
								onChange={(e) => setSelectedCourse(e.target.value)}
								required>
								<option value=''>Select a Course</option>
								<option value='course1'>Course 1</option>
								<option value='course2'>Course 2</option>
							</select>
						</div>

						<div>
							<label htmlFor='selectExam'>Select Exam:</label>
							<select
								id='selectExam'
								name='selectExam'
								value={selectedExam}
								onChange={(e) => setSelectedExam(e.target.value)}
								required>
								<option value=''>Select an Exam</option>
								<option value='exam1'>Exam 1</option>
								<option value='exam2'>Exam 2</option>
							</select>
						</div>

						<button type='submit'>Generate</button>
					</form>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default GenerateReports;
