/** @format */

import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import "./admin.css";
import "../roles.css";
import Navbar from "../Navbar";
import { API_URL } from "../../constants";
import { Link, useLocation } from "react-router-dom";

const Admin = () => {
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const user_id = searchParams.get("user_id");
	const nav = [
		["Home", `/admin?user_id=${user_id}`],
		["Profile", `/admin/profile?user_id=${user_id}`],
		["Messages", `/admin/messages?user_id=${user_id}`],
		// ["Permissions", `/admin/managePermissions?user_id=${user_id}`],
		["Generate Report", `${API_URL}/reports/generate_report.php`],
	];
	// console.log("User ID is " + user_id);
	return (
		<>
			<Header role='Admin' header={[["Admin Dashboard", ""]]} loggedIn={true} />
			<Navbar nav={nav} />
			<div className='wrapper' style={{ width: "auto" }}>
				<div className='container'>
					<div className='admin-cards'>
						<div className='course-card'>
							<h2>Students</h2>
							<div>
								<Link to={`/admin/manageStudents?user_id=${user_id}`}>
									<button
										className='btn'
										style={{ backgroundColor: "#982652" }}>
										Manage Students
									</button>
								</Link>
								<Link to={`/admin/performance?user_id=${user_id}`}>
									<button
										className='btn'
										style={{ backgroundColor: "#982652" }}>
										Reports
									</button>
								</Link>
							</div>
						</div>
					</div>
				</div>

				<div className='container'>
					<div className='admin-cards'>
						<div className='course-card'>
							<h2>Instructors</h2>
							<div>
								<Link to={`/admin/manageInstructors?user_id=${user_id}`}>
									<button
										className='btn'
										style={{ backgroundColor: "#982652" }}>
										View Instructors
									</button>
								</Link>
								<Link to={`/admin/performance?user_id=${user_id}`}>
									<button
										className='btn'
										style={{ backgroundColor: "#982652" }}>
										Reports
									</button>
								</Link>
							</div>
						</div>
					</div>
				</div>

				<div className='container'>
					<div className='admin-cards'>
						<div className='course-card'>
							<h2>QAO</h2>
							<div>
								<Link to={`/admin/manageQA?user_id=${user_id}`}>
									<button
										className='btn'
										style={{ backgroundColor: "#982652" }}>
										View Quality Assurance Officers
									</button>
								</Link>
								<Link to={`/admin/performance?user_id=${user_id}`}>
									<button
										className='btn'
										style={{ backgroundColor: "#982652" }}>
										Reports
									</button>
								</Link>
							</div>
						</div>
					</div>
				</div>
				<div className='container'>
					<div className='admin-cards'>
						<div className='course-card'>
							<h2>PC</h2>
							<div>
								<Link to={`/admin/managePC?user_id=${user_id}`}>
									<button
										className='btn'
										style={{ backgroundColor: "#982652" }}>
										View Program Coordinators
									</button>
								</Link>
								<Link to={`/admin/performance?user_id=${user_id}`}>
									<button
										className='btn'
										style={{ backgroundColor: "#982652" }}>
										Reports
									</button>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default Admin;
