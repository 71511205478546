/** @format */

import React, { useState, useEffect } from "react";
import Footer from "../Footer";
import Header from "../Header";
import Navbar from "../Navbar";
import { API_URL } from "../../constants";
import { Link, useLocation, useNavigate } from "react-router-dom";

const ManagePC = (props) => {
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const user_id = searchParams.get("user_id");
	const nav = [["Dashboard", `/admin?user_id=${user_id}`]];
	const [pcs, setPcs] = useState([]);

	useEffect(() => {
		fetch(API_URL + "admin/pc/get_pcs.php")
			.then((response) => response.json())
			.then((data) => {
				if (data.success) {
					setPcs(data.users);
				} else {
					console.error("Failed to fetch Program Coordinator data.");
				}
			});
	}, []);
	return (
		<>
			<Header
				role='Admin'
				header={[["Admin Management Console", ""]]}
				loggedIn={true}
			/>
			<Navbar nav={nav} />

			<div class='textBox'>
				<p class='text-heading'>Program Coordinator</p>
			</div>
			<div
				style={{
					backgroundColor: "#c3e9ce",
					width: "100%",
					textAlign: "center",
				}}>
				<Link
					to={`/admin/actionForm?user_id=${user_id}`}
					state={{
						role: "PC",
						nav: [
							["Home", `/admin?user_id=${user_id}`],
							["Manage QAs", `/admin/managePC?user_id=${user_id}`],
						],
						title: "Add New Program Coordinator",
						action: "Add",
					}}>
					<button className='btn' style={{ backgroundColor: "#982652" }}>
						Add Program Coordinator
					</button>
				</Link>
			</div>

			<div class='list-wrapper'>
				<table>
					<thead>
						<tr>
							<th>Name</th>
							<th>Employee ID</th>
							<th>Email Address</th>
							<th>Phone Number</th>

							<th>Edit</th>
							<th>Delete</th>
						</tr>
					</thead>
					<tbody>
						{pcs.map((pc) => (
							<tr key={pc.id}>
								<td>{`${pc.first_name} ${pc.last_name}`}</td>
								<td>{pc.id}</td>
								<td>{pc.email}</td>
								<td>{pc.phone}</td>

								<td>
									<Link
										to={`/admin/editAction?user_id=${user_id}&edit_id=${pc.id}`}
										state={{
											role: "PC",
											nav: [
												["Home", `/admin?user_id=${user_id}`],
												[
													"Manage Program Coordinators",
													`/admin/managePC?user_id=${user_id}`,
												],
											],
											title: "Change Program Coordinator Details",
											action: "Save",
											first_name: pc.first_name,
											last_name: pc.last_name,
											email: pc.email,
											contact: pc.phone,
										}}>
										<button
											className='btn'
											style={{ backgroundColor: "#982652" }}>
											Edit
										</button>
									</Link>
								</td>
								<td>
									<Link
										to='/admin/deleted'
										state={{
											role: "PC",
											nav: [["Dashboard", `/admin?user_id=${user_id}`]],
											props: [pc.id],
										}}>
										<button
											className='btn'
											style={{ backgroundColor: "#982652" }}>
											Delete
										</button>
									</Link>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
			<Footer />
		</>
	);
};

export default ManagePC;
