/** @format */

import React, { useState, useEffect } from "react";
import "./instructor.css";
import Header from "../Header";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { Link, useLocation } from "react-router-dom";
import { API_URL } from "../../constants";

const Instructor = () => {
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const user_id = searchParams.get("user_id");
	const [courses, setCourses] = useState([]);

	const nav = [
		["Home", `/instructor?user_id=${user_id}`],
		["Profile", `/instructor/profile?user_id=${user_id}`],

		["Messages", `/instructor/messages?user_id=${user_id}`],
		["Student Reports", `/instructor/performance?user_id=${user_id}`],
	];

	useEffect(() => {
		const fetchCourses = async () => {
			try {
				const response = await fetch(
					`${API_URL}instructor/course/get_courses.php?user_id=${user_id}`
				);

				if (response.ok) {
					const data = await response.json();
					setCourses(data.courses);
				} else {
					console.error("Failed to fetch courses.");
				}
			} catch (error) {
				console.error("Error while fetching courses:", error);
			}
		};

		fetchCourses();
	}, [user_id]);

	return (
		<div className='container-fluid'>
			<Header
				role='Instructor'
				header={[["Instructor Dashboard", ""]]}
				loggedIn={true}
			/>
			<Navbar nav={nav} />

			<div className='wrapper' style={{ alignItems: "flex-start" }}>
				<div className='instructor-container'>
					<div style={{ display: "flex", justifyContent: "center" }}>
						<Link
							to={`/instructor/courseForm?user_id=${user_id}`}
							state={{
								action: "Create",
							}}>
							<button style={{ backgroundColor: "#982652" }} className='btn'>
								Create Course
							</button>
						</Link>
					</div>

					<h2>Courses</h2>

					<div className='course-card-container'>
						{courses.length === 0 ? (
							<h1>You do not take any course this semester!</h1>
						) : (
							courses?.map((course) => (
								<Link
									key={course.id}
									to={`/instructor/courses?user_id=${user_id}&course_id=${course.id}&course_name=${course.name}`}>
									<div className='course-card'>
										<h3 className='course-title'>{course.name}</h3>
										<p className='course-details'>Course Code: {course.code}</p>

										<p className='course-details'>
											Schedule: {course.schedule}
										</p>

										<div className='card-buttons'>
											<Link
												to={`/instructor/updateCourse?course_id=${course.id}&user_id=${user_id}&course_name=${course.name}&course_code=${course.code}&course_schedule=${course.schedule}`}
												state={{ course_content: course.course_content }}>
												<button
													style={{ backgroundColor: "#982652" }}
													className='btn'>
													Update Course
												</button>
											</Link>
											<Link
												to={`/instructor/deleted?user_id=${user_id}`}
												state={{
													role: "Course",
													nav: [
														["Dashboard", `/instructor?user_id=${user_id}`],
													],
													props: [course.id],
												}}>
												<button
													style={{ backgroundColor: "#982652" }}
													className='btn'>
													Delete Course
												</button>
											</Link>
											<Link
												to={`/instructor/createExam?course_id=${course.id}&user_id=${user_id}&course_name=${course.name}`}
												state={{
													title: "Create Exam",
													nav: nav,
													action: "Create",
												}}>
												<button
													style={{ backgroundColor: "#982652" }}
													className='btn'>
													Create Exam
												</button>
											</Link>
										</div>
									</div>
								</Link>
							))
						)}
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default Instructor;
