/** @format */

import React, { useState } from "react";
import Header from "./Header";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { useLocation, useNavigate } from "react-router-dom";
import { API_URL } from "../constants";

const EditProfile = ({ role }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const user_id = searchParams.get("user_id");

	const [first_name, setFirstName] = useState("");
	const [last_name, setLastName] = useState("");

	const [email, setEmail] = useState("");
	// const [id, setId] = useState("");
	const [contact, setContact] = useState("");
	const [errors, setErrors] = useState({});

	const handleSubmit = (e) => {
		e.preventDefault();
		const validationErrors = {};

		if (!first_name.trim()) {
			validationErrors.first_name = "First Name is required";
		}
		if (!last_name.trim()) {
			validationErrors.last_name = "Last Name is required";
		}

		if (!email.trim()) {
			validationErrors.email = "Email is required";
		} else if (!isValidEmail(email)) {
			validationErrors.email = "Invalid email format";
		}

		if (!contact.trim()) {
			validationErrors.contact = "Mobile Contact is required";
		}

		if (Object.keys(validationErrors).length === 0) {
			// Construct the FormData object with user information
			const formData = new FormData();
			formData.append("user_id", user_id);
			formData.append("first_name", first_name);
			formData.append("last_name", last_name);
			formData.append("email", email);
			formData.append("phone", contact);

			// Make the POST request to update the profile
			updateProfile(formData);
		} else {
			setErrors(validationErrors);
		}
	};

	const isValidEmail = (email) => {
		const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
		return emailPattern.test(email);
	};

	const updateProfile = async (formData) => {
		const endpoint = `${API_URL}profile/update_profile.php`;

		try {
			const response = await fetch(endpoint, {
				method: "POST",
				body: formData,
			});

			if (response.ok) {
				const data = await response.json();
				console.log(data);
				if (data.success) {
					alert("Profile updated!");
					navigate(`/${role.toLowerCase()}/profile?user_id=${user_id}`);
				} else {
					console.error("Failed to update profile");
				}
			} else {
				console.error("Failed to update profile");
			}
		} catch (error) {
			console.error("Error updating profile:", error);
		}
	};

	return (
		<>
			<Header role={role} header={[["Edit Profile", ""]]} loggedIn={true} />
			<Navbar
				nav={[
					["Home", `/${role.toLowerCase()}?user_id=${user_id}`],
					["Profile", `/${role.toLowerCase()}/profile?user_id=${user_id}`],

					["Messages", `/${role.toLowerCase()}/messages?user_id=${user_id}`],
					["Report", `/${role.toLowerCase()}/performance?user_id=${user_id}`],
				]}
			/>

			<div className='wrapper'>
				<div className='profile-form'>
					<h1>Update Your Profile</h1>
					<form onSubmit={handleSubmit}>
						<div className='form-group'>
							<label htmlFor='first_name'>First Name</label>
							<input
								type='text'
								id='first_name'
								name='first_name'
								placeholder='First Name'
								value={first_name}
								onChange={(e) => setFirstName(e.target.value)}
							/>
							{errors.first_name && (
								<div className='error-message'>{errors.first_name}</div>
							)}
						</div>
						<div className='form-group'>
							<label htmlFor='last_name'>Last Name</label>
							<input
								type='text'
								id='last_name'
								name='last_name'
								placeholder='Last Name'
								value={last_name}
								onChange={(e) => setLastName(e.target.value)}
							/>
							{errors.last_name && (
								<div className='error-message'>{errors.last_name}</div>
							)}
						</div>
						<div className='form-group'>
							<label htmlFor='email'>Email Address</label>
							<input
								type='text'
								id='email'
								name='email'
								placeholder='Email Address'
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>
							{errors.email && (
								<div className='error-message'>{errors.email}</div>
							)}
						</div>

						<div className='form-group'>
							<label htmlFor='contact'>Mobile Contact</label>
							<input
								type='text'
								id='contact'
								name='contact'
								placeholder='Mobile Contact'
								value={contact}
								onChange={(e) => setContact(e.target.value)}
							/>
							{errors.contact && (
								<div className='error-message'>{errors.contact}</div>
							)}
						</div>
						<div className='update-button'>
							<button type='submit'>Update Profile</button>
						</div>
					</form>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default EditProfile;
