/** @format */

import React from "react";
import "./qa.css";
import Header from "../Header";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { Link, useLocation } from "react-router-dom";
const QA = () => {
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const user_id = searchParams.get("user_id");
	return (
		<>
			<Header
				role='QA'
				header={[["QA Officer Dashboard", ""]]}
				loggedIn={true}
			/>
			<Navbar
				nav={[
					["Home", `/qa?user_id=${user_id}`],
					["Profile", `/qa/profile?user_id=${user_id}`],
					["Messages", `/qa/messages?user_id=${user_id}`],
				]}
			/>
			<div className='home-wrapper'>
				{/* <div className='qa-container'>
					<div className='container-title'>Collaborate</div>
					<div className='button-container'>
						<button className='main-button'>
							<Link to='/qa/messages'>Connect with Instructors</Link>
						</button>

						<button className='main-button'>
							<Link to='/qa/messages'>Connect with Students</Link>
						</button>

						<button className='main-button'>
							<Link to='/qa/messages'>Connect with Administrator</Link>
						</button>
					</div>
				</div> */}
				<div className='container'>
					<div className='container-title'>Policies</div>
					<div className='button-container'>
						<button className='main-button'>
							<Link
								to={`/qa/createPolicy?user_id=${user_id}`}
								state={{ role: "QA" }}>
								Create New Policy
							</Link>
						</button>
						<button className='main-button'>
							<Link
								to={`/qa/policies?user_id=${user_id}`}
								state={{ role: "QA" }}>
								Check Existing Policies
							</Link>
						</button>
					</div>
				</div>
				<div className='container'>
					<div className='container-title'>Monitor</div>
					<div className='button-container'>
						<button className='main-button'>
							<Link to={`/qa/messages?user_id=${user_id}`}>Requests</Link>
						</button>
						<button className='main-button'>
							<Link to={`/qa/performance?user_id=${user_id}`}>
								Performance Monitoring
							</Link>
						</button>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default QA;
