/** @format */

import React, { useState } from "react";
import "./instructor.css";
import Header from "../Header";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { useLocation, useNavigate } from "react-router-dom";
import { API_URL } from "../../constants";

const UpdateCourse = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const course_id = searchParams.get("course_id");
	const user_id = searchParams.get("user_id");
	const course_name = searchParams.get("course_name");
	const course_code = searchParams.get("course_code");
	const course_schedule = searchParams.get("course_schedule");
	const course_content = location?.state?.course_content;

	// Define state variables to track the form inputs
	const [name, setName] = useState(course_name);
	const [code, setCode] = useState(course_code);
	const [schedule, setSchedule] = useState(course_schedule);
	const [content, setContent] = useState(course_content);

	const handleSubmit = async (e) => {
		e.preventDefault();

		// Create a FormData object to send data as a POST request
		const formData = new FormData();
		formData.append("course_id", course_id);
		formData.append("user_id", user_id);
		formData.append("name", name);
		formData.append("code", code);
		formData.append("schedule", schedule);
		formData.append("course_content", content);

		try {
			const response = await fetch(
				API_URL + "instructor/course/update_course.php",
				{
					method: "POST",
					body: formData,
				}
			);

			if (response.ok) {
				alert("Course updated successfully.");
				navigate(`/instructor?user_id=${user_id}`);
			} else {
				// Handle the case where the update failed
				alert("Course update failed.");
			}
		} catch (error) {
			alert("Error while updating course:", error);
		}
	};

	return (
		<>
			<Header
				role='Instructor'
				header={[["Instructor Dashboard", ""]]}
				loggedIn={true}
			/>
			<Navbar nav={[["Dashboard", `/instructor?user_id=${user_id}`]]} />
			<div className='wrapper'>
				<div className='container'>
					<h2 style={{ textAlign: "center" }}>Update Course</h2>
					<form onSubmit={handleSubmit}>
						<div className='form-group'>
							<label htmlFor='name'>Course Name:</label>
							<input
								type='text'
								id='name'
								name='name'
								placeholder='Course Name'
								value={name}
								onChange={(e) => setName(e.target.value)}
								required
							/>
						</div>
						<div className='form-group'>
							<label htmlFor='code'>Course Code:</label>
							<input
								type='text'
								id='code'
								name='code'
								placeholder='Course Code'
								value={code}
								onChange={(e) => setCode(e.target.value)}
								required
							/>
						</div>
						<div className='form-group'>
							<label htmlFor='schedule'>Schedule:</label>
							<input
								type='text'
								id='schedule'
								name='schedule'
								placeholder='Schedule'
								value={schedule}
								onChange={(e) => setSchedule(e.target.value)}
								required
							/>
						</div>
						<div className='form-group'>
							<label htmlFor='content'>Course Content:</label>
							<textarea
								type='text'
								id='content'
								name='content'
								rows='4'
								required
								value={content}
								onChange={(e) => setContent(e.target.value)}
							/>
						</div>
						<div className='btn-container'>
							<button type='submit' className='btn'>
								Update Course
							</button>
						</div>
					</form>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default UpdateCourse;
