/** @format */

import React from "react";
import "./student.css";
import Header from "../Header";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { Link, useLocation, useNavigate } from "react-router-dom";

const Student = () => {
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const user_id = searchParams.get("user_id");

	return (
		<>
			<Header
				role='Student'
				header={[["Student Dashboard", ""]]}
				loggedIn={true}
			/>
			<Navbar
				nav={[
					["Home", `/student?user_id=${user_id}`],
					["Profile", `/student/profile?user_id=${user_id}`],
					["My Courses", `/student/courses?user_id=${user_id}`],
					["Messages", `/student/messages?user_id=${user_id}`],
				]}
			/>
			<div className='student-container' style={{ minHeight: "70vh" }}>
				<h2>Academic Program Overview</h2>
				<p style={{ fontSize: "18px" }}>
					This is an overview of your academic program of Masters in Computer
					Science
				</p>

				<h3>Program Objectives</h3>
				<ul style={{ fontSize: "18px" }}>
					<li>
						Objective 1: Fulfill degree requirements by completing 36 credit
						courses.
					</li>
					<li>
						Objective 2: Maintaining good academic standing by obtaining a good
						Grade.
					</li>
					<li>
						Objective 3: Taking away the set skills and expectations through the
						program's learnings.
					</li>
				</ul>
				<h3>How-to Guide</h3>
				<ul style={{ fontSize: "18px" }}>
					<li>
						Login: To access the Student Dashboard, log in with your credentials
						provided by your institution.
					</li>
					<li>
						Dashboard Overview: Upon login, you'll land on the dashboard's main
						page. Here, you'll find a welcome message, enrolled courses and a
						navigation menu.
					</li>
					<li>
						Courses: Click on the "Courses" section in the navigation menu to
						view a list of your enrolled courses. Each course card displays the
						course name, course code and other relevant information. You can
						click on a course card to access detailed information about the
						course.
					</li>
					<li>
						User Profile: To view and update your profile information, click on
						the "User Profile" section. You can edit your contact details,
						including your name and contact number. Remember to save any changes
						you make.
					</li>
				</ul>

				{/* <h2>Enrolled Courses</h2>
				<div className='course-card-container'>
					<Link to='/student/courseDetails' className='student-course-card'>
						<h3 className='course-title'>Data Structures & Algorithms</h3>
						<p className='course-details'>Course Code: CSE 5311</p>
						<p className='course-details'>Instructor: John Doe</p>
						<p className='course-details'>
							Schedule: Mondays and Wednesdays, 10:00 AM - 11:30 AM
						</p>
						<p className='course-details'>Next Exam Date: October 15, 2023</p>
					</Link>

					<Link to='/student/courseDetails' className='student-course-card'>
						<h3 className='course-title'>Distributed Systems</h3>
						<p className='course-details'>Course Code: CSE 5308</p>
						<p className='course-details'>Instructor: Jane Smith</p>
						<p className='course-details'>
							Schedule: Tuesdays and Thursdays, 2:00 PM - 3:30 PM
						</p>
						<p className='course-details'>Next Exam Date: October 18, 2023</p>
					</Link>
				</div> */}
			</div>

			{/* <div className='wrapper-bottom'>
				<h2 className='upcoming-events'>Upcoming Assignments and Exams</h2>
				<ul className='event-list'>
					<li className='event-item'>Exam 1: Programming Assignment</li>
					<li className='event-item'>Exam 2: Website Project</li>
				</ul>
			</div> */}
			<Footer />
		</>
	);
};

export default Student;
