/** @format */

import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../Footer";
import Header from "../Header";
import Navbar from "../Navbar";
import { API_URL } from "../../constants";

const CreatePolicy = (props) => {
	const navigate = useNavigate();
	const location = useLocation();
	const role = location?.state?.role;
	const searchParams = new URLSearchParams(location.search);
	const user_id = searchParams.get("user_id");
	// const action = location?.state?.action;
	const [policyTitle, setPolicyTitle] = useState("");
	const [courseID, setCourseID] = useState("");
	const [description, setDescription] = useState("");

	const handleSubmit = async (e) => {
		e.preventDefault();

		// Form validation
		if (!policyTitle || !courseID || !description) {
			alert("All fields are required.");
		} else {
			const formData = new FormData();
			formData.append("user_id", user_id);
			formData.append("title", policyTitle);
			formData.append("description", description);
			formData.append("course_id", courseID);

			try {
				const response = await fetch(API_URL + "qa/new_policy.php", {
					method: "POST",
					body: formData,
				});

				if (response.ok) {
					alert("Policy created successfully");
					navigate(`/${role.toLowerCase()}?user_id=${user_id}`);
				} else {
					alert("Failed to create policy. Please try again.");
				}
			} catch (error) {
				console.error("Error while creating policy:", error);
				alert("An error occurred while creating the policy.");
			}
		}
	};

	return (
		<>
			<Header
				role={role}
				header={[[`${role} Dashboard`, ""]]}
				loggedIn={true}
			/>
			<Navbar nav={[["Home", `/${role.toLowerCase()}?user_id=${user_id}`]]} />
			<div className='wrapper'>
				<div className='container'>
					<h2 style={{ textAlign: "center" }}>Create Policy</h2>
					<form className='form' onSubmit={handleSubmit}>
						<div className='form-group'>
							<label htmlFor='policyTitle'>Policy Title:</label>
							<input
								type='text'
								id='policyTitle'
								name='policyTitle'
								value={policyTitle}
								onChange={(e) => setPolicyTitle(e.target.value)}
							/>
						</div>

						<div className='form-group'>
							<label htmlFor='courseID'>Course Code:</label>
							<input
								type='text'
								id='courseID'
								name='courseID'
								value={courseID}
								onChange={(e) => setCourseID(e.target.value)}
							/>
						</div>

						<div className='form-group'>
							<label htmlFor='description'>Description:</label>
							<input
								type='text'
								id='description'
								name='description'
								value={description}
								onChange={(e) => setDescription(e.target.value)}
							/>
						</div>
						<div style={{ textAlign: "center" }}>
							<button type='submit' className='btn'>
								Create Policy
							</button>
						</div>
					</form>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default CreatePolicy;
