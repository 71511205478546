/** @format */

import "./App.css";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import Home from "./components/Home/Home";
import Services from "./components/Home/Services";
import Contact from "./components/Home/Contact";
import AboutUs from "./components/Home/AboutUs";
import Login from "./components/Home/Login";
import ResetPassword from "./components/Home/ResetPassword";
import SignUp from "./components/Home/SignUp";
import Admin from "./components/Admin/Admin";
import Profile from "./components/Profile";
import EditProfile from "./components/EditProfile";
import ManageStudents from "./components/Admin/ManageStudents";
import ActionForm from "./components/Admin/ActionForm";
import Deleted from "./components/Deleted";
import ManageInstructors from "./components/Admin/ManageInstructors";
import ManageQA from "./components/Admin/ManageQA";
import ManagePC from "./components/Admin/ManagePC";
import ManagePermissions from "./components/Admin/ManagePermissions";
import Messages from "./components/Messages";
import Performance from "./components/Performance";
import GenerateReports from "./components/Admin/GenerateReports";
import Student from "./components/Student/Student";
import StudentCourses from "./components/Student/StudentCourses";
import StudentCourseDetails from "./components/Student/StudentCourseDetails";
import Instructor from "./components/Instructor/Instructor";
import UpdateCourse from "./components/Instructor/UpdateCourse";
import InstructorActionForm from "./components/Instructor/InstructorActionForm";
import CourseForm from "./components/Instructor/CourseForm";
import InstructorCourses from "./components/Instructor/InstructorCourses";
import InstructorGrades from "./components/Instructor/InstructorGrades";
import QA from "./components/QA/QA";
import CreatePolicy from "./components/QA/CreatePolicy";
import Policies from "./components/QA/Policies";
import CreateRecommendation from "./components/QA/CreateRecommendation";
import CheckRecommendations from "./components/QA/CheckRecommendations";
import ProgramCoordinator from "./components/PC/ProgramCoordinator";
import UpdatePolicy from "./components/QA/UpdatePolicy";
import EditAction from "./components/Admin/EditAction";

const appRouter = createBrowserRouter([
	{
		path: "/",
		element: <Home />,
	},
	{
		path: "/services",
		element: <Services />,
	},
	{
		path: "/contact",
		element: <Contact />,
	},
	{ path: "/about", element: <AboutUs /> },
	{ path: "/login", element: <Login /> },
	{ path: "/resetPassword", element: <ResetPassword /> },
	{ path: "/signUp", element: <SignUp /> },
	// admin
	{
		path: "/admin",
		element: <Outlet />,
		children: [
			{ path: "", element: <Admin /> },
			{
				path: "profile",
				element: (
					<Profile
						role='Admin'
						nav={[
							["Home", "/admin"],
							["Profile", "/admin/profile"],
							["Messages", "/admin/messages"],
							["Reports", "/admin/performance"],
						]}
					/>
				),
			},
			{
				path: "editProfile",
				element: (
					<EditProfile
						role='Admin'
						nav={[
							["Home", "/admin"],
							["Profile", "/admin/profile"],
							["Messages", "/admin/messages"],
							["Reports", "/admin/performance"],
						]}
					/>
				),
			},
			{ path: "manageStudents", element: <ManageStudents /> },
			{ path: "actionForm", element: <ActionForm /> },
			{ path: "editAction", element: <EditAction /> },

			{ path: "deleted", element: <Deleted /> },
			{ path: "manageInstructors", element: <ManageInstructors /> },
			{ path: "manageQA", element: <ManageQA /> },
			{ path: "managePC", element: <ManagePC /> },
			{ path: "managePermissions", element: <ManagePermissions /> },
			{ path: "generateReports", element: <GenerateReports /> },
			{
				path: "performance",
				element: <Performance role='admin' />,
			},

			{
				path: "messages",
				element: (
					<Messages
						role='Admin'
						nav={[
							["Home", "/admin"],
							["Profile", "/admin/profile"],
						]}
					/>
				),
			},
		],
	},
	// student
	{
		path: "/student",
		element: <Outlet />,
		children: [
			{ path: "", element: <Student /> },
			{
				path: "profile",
				element: (
					<Profile
						role='Student'
						nav={[
							["Home", "/student"],
							["Profile", "/student/profile"],
							["Courses", "/student/courses"],
							["Messages", "/student/messages"],
						]}
					/>
				),
			},
			{
				path: "editProfile",
				element: (
					<EditProfile
						role='Student'
						nav={[
							["Home", "/student"],
							["Profile", "/student/profile"],
							["Courses", "/student/courses"],
							["Messages", "/student/messages"],
						]}
					/>
				),
			},
			{
				path: "performance",
				element: <Performance role='Student' nav={[["Home", "/student"]]} />,
			},
			{
				path: "messages",
				element: (
					<Messages
						role='Student'
						nav={[
							["Home", "/student"],
							["Profile", "/student/profile"],
						]}
					/>
				),
			},
			{ path: "courses", element: <StudentCourses /> },
			{ path: "courseDetails", element: <StudentCourseDetails /> },
			{ path: "deleted", element: <Deleted /> },
		],
	},
	// instructor
	{
		path: "/instructor",
		element: <Outlet />,
		children: [
			{ path: "", element: <Instructor /> },
			{
				path: "profile",
				element: <Profile role='Instructor' />,
			},
			{
				path: "editProfile",
				element: <EditProfile role='Instructor' />,
			},

			{
				path: "performance",
				element: <Performance role='instructor' />,
			},
			{
				path: "messages",
				element: (
					<Messages
						role='Instructor'
						nav={[
							["Home", "/instructor"],
							["Profile", "/instructor/profile"],
						]}
					/>
				),
			},
			{ path: "deleted", element: <Deleted /> },
			{ path: "updateCourse", element: <UpdateCourse /> },
			{ path: "createExam", element: <InstructorActionForm /> },
			{ path: "courseForm", element: <CourseForm /> },
			{ path: "courses", element: <InstructorCourses /> },
			{ path: "grades", element: <InstructorGrades /> },
		],
	},
	// qa
	{
		path: "/qa",
		element: <Outlet />,
		children: [
			{ path: "", element: <QA /> },
			{
				path: "profile",
				element: (
					<Profile
						role='QA'
						nav={[
							["Home", "/qa"],
							["Profile", "/qa/profile"],

							["Messages", "/qa/messages"],
						]}
					/>
				),
			},
			{
				path: "editProfile",
				element: (
					<EditProfile
						role='QA'
						nav={[
							["Home", "/qa"],
							["Profile", "/qa/profile"],

							["Messages", "/qa/messages"],
						]}
					/>
				),
			},
			{
				path: "performance",
				element: <Performance role='qa' />,
			},
			{
				path: "messages",
				element: (
					<Messages
						role='QA'
						nav={[
							["Home", "/qa"],
							["Profile", "/qa/profile"],
						]}
					/>
				),
			},
			{ path: "policies", element: <Policies /> },
			{ path: "createPolicy", element: <CreatePolicy /> },
			{ path: "updatePolicy", element: <UpdatePolicy /> },

			{ path: "createRecommendation", element: <CreateRecommendation /> },
			{ path: "checkRecommendations", element: <CheckRecommendations /> },
			{ path: "deleted", element: <Deleted /> },
		],
	},
	// program coordinator
	{
		path: "/pc",
		element: <Outlet />,
		children: [
			{ path: "", element: <ProgramCoordinator /> },
			{
				path: "profile",
				element: (
					<Profile
						role='PC'
						nav={[
							["Home", "/pc"],
							["Profile", "/pc/profile"],

							["Messages", "/pc/messages"],
						]}
					/>
				),
			},
			{ path: "policies", element: <Policies /> },
			{ path: "createPolicy", element: <CreatePolicy /> },
			{ path: "updatePolicy", element: <UpdatePolicy /> },
			{
				path: "editProfile",
				element: (
					<EditProfile
						role='PC'
						nav={[
							["Home", "/pc"],
							["Profile", "/pc/profile"],

							["Messages", "/pc/messages"],
						]}
					/>
				),
			},
			{
				path: "performance",
				element: <Performance role='pc' />,
			},
			{
				path: "messages",
				element: (
					<Messages
						role='PC'
						nav={[
							["Home", "/pc"],
							["Profile", "/pc/profile"],
						]}
					/>
				),
			},
			{ path: "deleted", element: <Deleted /> },
		],
	},
]);

function App() {
	return <RouterProvider router={appRouter} className='App'></RouterProvider>;
}

export default App;
