/** @format */

import React, { useEffect } from "react";
// import grade1 from "../images/grade1.png";
// import grade2 from "../images/grade2.jpeg";
import Header from "./Header";
import Navbar from "./Navbar";
import Footer from "./Footer";
import * as echarts from "echarts";
import { Link, useLocation } from "react-router-dom";
import { API_URL } from "../constants";

const Performance = ({ role }) => {
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const user_id = searchParams.get("user_id");
	const nav = [["Home", `/${role.toLowerCase()}?user_id=${user_id}`]];
	useEffect(() => {
		const endpoint = `${API_URL}reports/course_enrollment_count.php`;
		fetch(endpoint)
			.then((response) => response.json())
			.then((data) => {
				if (data.success) {
					// setUsers(data.users);
					console.log(data.courses);

					let xArray = [];
					let yArray = [];

					data.courses.forEach((item) => {
						xArray.push(item.course_code);
						yArray.push(item.enrolled_students);
					});

					const dom = document.getElementById("chart-container");
					const myChart = echarts.init(dom);

					const option = {
						xAxis: {
							type: "category",
							data: xArray,
						},
						yAxis: {
							type: "value",
						},
						series: [
							{
								data: yArray,
								type: "bar",

								colorBy: "data",
							},
						],
					};

					if (option && typeof option === "object") {
						myChart.setOption(option);
					}

					window.addEventListener("resize", function () {
						myChart.resize();
					});
				} else {
					console.error("Failed to fetch user data.");
				}
			});
	}, []);

	useEffect(() => {
		const endpoint = `${API_URL}reports/course_policy_count.php`;
		fetch(endpoint)
			.then((response) => response.json())
			.then((data) => {
				if (data.success) {
					// setUsers(data.users);
					console.log(data.course_policies);

					let xArray = [];
					let yArray = [];

					data.course_policies.forEach((item) => {
						xArray.push(item.course_code);
						yArray.push(item.policy_count);
					});

					const dom = document.getElementById("policy-container");
					const myChart = echarts.init(dom);

					const option = {
						xAxis: {
							type: "category",
							data: xArray,
						},
						yAxis: {
							type: "value",
						},
						series: [
							{
								data: yArray,
								type: "line",
								symbol: "triangle",
								symbolSize: 20,
								lineStyle: {
									color: "#5470C6",
									width: 4,
									type: "dashed",
								},
								itemStyle: {
									borderWidth: 3,
									borderColor: "#EE6666",
									color: "yellow",
								},
							},
						],
					};

					if (option && typeof option === "object") {
						myChart.setOption(option);
					}

					window.addEventListener("resize", function () {
						myChart.resize();
					});
				} else {
					console.error("Failed to fetch user data.");
				}
			});
	}, []);

	// useEffect(() => {
	// 	const endpoint = `${API_URL}reports/course_policy_count.php`;
	// 	fetch(endpoint)
	// 		.then((response) => response.json())
	// 		.then((data) => {
	// 			if (data.success) {
	// 				// setUsers(data.users);
	// 				console.log(data.course_policies);

	// 				let xArray = [];
	// 				let yArray = [];

	// 				data.course_policies.forEach((item) => {
	// 					xArray.push(item.course_code);
	// 					yArray.push(item.policy_count);
	// 				});
	// 				const pieData = [];

	// 				for (let i = 0; i < xArray.length; i++) {
	// 					pieData.push({
	// 						value: yArray[i],
	// 						name: xArray[i],
	// 					});
	// 				}

	// 				const dom = document.getElementById("policy-container");
	// 				const myChart = echarts.init(dom);

	// 				const option = {
	// 					tooltip: {
	// 						trigger: "item",
	// 					},
	// 					legend: {
	// 						orient: "vertical",
	// 						left: "left",
	// 					},
	// 					series: [
	// 						{
	// 							name: "Access From",
	// 							type: "pie",
	// 							radius: "50%",
	// 							data: pieData,
	// 							emphasis: {
	// 								itemStyle: {
	// 									shadowBlur: 10,
	// 									shadowOffsetX: 0,
	// 									shadowColor: "rgba(0, 0, 0, 0.5)",
	// 								},
	// 							},
	// 						},
	// 					],
	// 				};

	// 				if (option && typeof option === "object") {
	// 					myChart.setOption(option);
	// 				}

	// 				window.addEventListener("resize", function () {
	// 					myChart.resize();
	// 				});
	// 			} else {
	// 				console.error("Failed to fetch user data.");
	// 			}
	// 		});
	// }, []);
	return (
		<>
			<Header role={role} header={[["Report", ""]]} loggedIn={true} />
			<Navbar nav={nav} />
			<div style={{ minHeight: "70vh" }}>
				<h4 style={{ textAlign: "center" }}>
					The below performance report depicts the distribution of number of
					enrolled students in different courses offered in Masters of Computer
					Science program. The X axis shows the course code and the Y axis shows
					the number of enrolled students.
				</h4>
				<div id='chart-container' style={{ height: "400px" }}></div>

				{/* <div class='image-container'>

					<img src={grade2} alt='Image2' />
				</div> */}

				<h4 style={{ textAlign: "center" }}>
					The below performance report depicts the distribution of number of
					policies created for different courses offered in Masters of Computer
					Science program. The X axis shows the course code and the Y axis shows
					the number of policies for each course.
				</h4>
				<div id='policy-container' style={{ height: "400px" }}></div>
			</div>
			<Footer />
		</>
	);
};

export default Performance;
