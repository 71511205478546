/** @format */

import React, { useState, useEffect } from "react";
import Header from "../Header";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { Link, useLocation } from "react-router-dom";
import { API_URL } from "../../constants";

const StudentCourseDetails = () => {
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const user_id = searchParams.get("user_id");
	const course_id = searchParams.get("course_id");
	const course_content = location?.state?.course_content;
	// console.log(course_content);
	const [examFeedback, setExamFeedback] = useState([]);
	const [exams, setExams] = useState([]);

	useEffect(() => {
		const fetchExams = async () => {
			try {
				const response = await fetch(
					`${API_URL}instructor/exam/get_exams.php?course_id=${course_id}`
				);

				if (response.ok) {
					const data = await response.json();
					setExams(data.exams);
					console.log(data.exams);
				} else {
					console.error("Failed to fetch exams.");
				}
			} catch (error) {
				console.error("Error while fetching exams:", error);
			}
		};

		fetchExams();
	}, [course_id]);
	useEffect(() => {
		const fetchGrades = async () => {
			try {
				const response = await fetch(
					`${API_URL}student/course/get_all_exam_grades.php?student_id=${user_id}&course_id=${course_id}`
				);

				if (response.ok) {
					const data = await response.json();
					console.log(data);
					setExamFeedback(data.exams);
					// console.log(examFeedback);
				} else {
					console.error("Failed to fetch courses.");
				}
			} catch (error) {
				console.error("Error while fetching courses:", error);
			}
		};

		fetchGrades();
	}, []);
	return (
		<>
			<Header role='Student' header={[["Courses", ""]]} loggedIn={true} />
			<Navbar
				nav={[
					["Home", `/student?user_id=${user_id}`],
					["Profile", `/student/profile?user_id=${user_id}`],
					["My Courses", `/student/courses?user_id=${user_id}`],
					["Messages", `/student/messages?user_id=${user_id}`],
				]}
			/>
			<div style={{ padding: "10px 30px", backgroundColor: "#c3e9ce" }}>
				<h2>Course Content</h2>
				<h3>{course_content}</h3>
			</div>
			<div className='wrapper' style={{ alignItems: "flex-start" }}>
				<div className='exam-container'>
					{/* <h2>Syllabus</h2>
					<p>
						Click <a href='student_course_details.html'>here</a> to view the
						syllabus
					</p>
					<br />

					<h2>Course Modules</h2>
					<p>
						Click <a href='student_course_details.html'>here</a> to access
						course modules
					</p> */}
					{/* <br /> */}

					{/* {examFeedback.length !== 0 ? (
						examFeedback.map((exam) => (
							<div>
								<h1>Graded Assignments / Exams</h1>
								<div className='graded-item' style={{ fontSize: "18px" }}>
									<p>
										{exam.exam_name} : {exam.grade}
									</p>
									<p>Feedback : {exam.instructor_feedback}</p>
									<p>Max Score : {exam.max_score}</p>
								</div>
							</div>
						))
					) : (
						<div>No Exams posted yet</div>
					)} */}

					{exams.length !== 0 ? (
						examFeedback.length !== 0 ? (
							examFeedback.map((exam) => (
								<div key={exam.exam_id}>
									<h1>Your Assignments / Exams Grades</h1>
									{exams.map((examDetail) => (
										<div key={examDetail.exam_id}>
											<div
												className='graded-item'
												style={{
													fontSize: "20px",
													border: "1px dashed black",
													padding: "10px",
												}}>
												<p>
													<strong>{examDetail.exam_name} </strong>:{" "}
													{exam.grade ? exam.grade : "Grades not posted"}
												</p>
												<p>
													<strong>Feedback :</strong>
													{exam.instructor_feedback
														? exam.instructor_feedback
														: "No feedback posted"}
												</p>
												<p>
													<strong>Max Score : </strong>
													{examDetail.max_score}
												</p>
											</div>
										</div>
									))}
								</div>
							))
						) : (
							<div>
								<h1>Assignments</h1>
								{exams.map((examDetail) => (
									<div key={examDetail.exam_id}>
										<div
											className='graded-item'
											style={{ border: "1px dashed black", padding: "10px" }}>
											<p>
												<strong>{examDetail.exam_name}</strong> : Instructor has
												not released the grades yet.
											</p>
											<p>
												<strong>Feedback : </strong> Instructor has not released
												the feedback yet.
											</p>
											<p>
												<strong>Max Score :</strong> {examDetail.max_score}
											</p>
										</div>
									</div>
								))}
							</div>
						)
					) : (
						<h3 className='btn-center'>No Upcoming Exams/Assignments. </h3>
					)}

					{/* <h2>Upcoming Exam</h2>
					<div className='upcoming-exam'>
						<p>Next Exam: September 29 - 12pm</p>
						<a href='student_course_details.html' className='exam-link'>
							Take Exam
						</a>
					</div> */}
				</div>
			</div>
			<Footer />
		</>
	);
};

export default StudentCourseDetails;
