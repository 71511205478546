/** @format */

import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../Footer";
import Header from "../Header";
import Navbar from "../Navbar";
import { API_URL } from "../../constants";

const EditAction = (props) => {
	const navigate = useNavigate();
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const user_id = searchParams.get("user_id");
	const edit_id = searchParams.get("edit_id");
	const role = location?.state?.role;
	const nav = location?.state?.nav;
	const title = location?.state?.title;
	const action = location?.state?.action;
	const first_name = location?.state?.first_name;
	const last_name = location?.state?.last_name;
	const email = location?.state?.email;
	const contact = location?.state?.contact;
	const [formData, setFormData] = useState({
		studentFirstName: first_name,
		studentLastName: last_name,
		studentEmail: email,
		studentPhone: contact,
	});
	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};
	const [instructorFormData, setInstructorFormData] = useState({
		instructorFirstName: first_name,
		instructorLastName: last_name,
		instructorEmail: email,
		instructorPhone: contact,
	});
	const handleInstructorChange = (e) => {
		const { name, value } = e.target;
		setInstructorFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	const [qaFormData, setQAFormData] = useState({
		qaFirstName: first_name,
		qaLastName: last_name,
		qaEmail: email,
		qaPhone: contact,
	});

	const handleQAChange = (e) => {
		const { name, value } = e.target;
		setQAFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};
	const [pcFormData, setPCFormData] = useState({
		pcFirstName: first_name,
		pcLastName: last_name,
		pcEmail: email,
		pcPhone: contact,
	});

	const handlePCChange = (e) => {
		const { name, value } = e.target;
		setPCFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		// Make the API call only if the role is "Student"
		if (role === "Student") {
			const { studentFirstName, studentLastName, studentEmail, studentPhone } =
				formData;

			if (
				!studentFirstName ||
				!studentLastName ||
				!studentEmail ||
				!studentPhone
			) {
				alert("Please fill in all required fields.");
				return;
			}

			const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
			if (!emailPattern.test(studentEmail)) {
				alert("Invalid email format. Please enter a valid email address.");
				return;
			}
			const apiURL = API_URL + "admin/update_student.php";

			const body = new FormData();
			body.append("user_id", edit_id); // passing student's id
			body.append("first_name", studentFirstName);
			body.append("last_name", studentLastName);
			body.append("email", studentEmail);
			body.append("phone", studentPhone);

			fetch(apiURL, {
				method: "POST",
				body: body,
			})
				.then((response) => {
					if (response.ok) {
						alert("Student updated successfully!");
						navigate(`/admin/manageStudents?user_id=${user_id}`);
					} else {
						alert("Failed to update student.");
					}
				})
				.catch((error) => {
					console.error("Error while updating student:", error);
					alert("An error occurred while updating student.");
				});
		}

		//instructor
		if (role === "Instructor") {
			const {
				instructorFirstName,
				instructorLastName,
				instructorEmail,
				instructorPhone,
			} = instructorFormData;

			if (
				!instructorFirstName ||
				!instructorLastName ||
				!instructorEmail ||
				!instructorPhone
			) {
				alert("Please fill in all required fields.");
				return;
			}

			const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
			if (!emailPattern.test(instructorEmail)) {
				alert("Invalid email format. Please enter a valid email address.");
				return;
			}
			const apiURL = API_URL + "admin/instructor/update_instructor.php";

			const body = new FormData();
			body.append("user_id", edit_id); // passing instructor's id
			body.append("first_name", instructorFirstName);
			body.append("last_name", instructorLastName);
			body.append("email", instructorEmail);
			body.append("phone", instructorPhone);

			fetch(apiURL, {
				method: "POST",
				body: body,
			})
				.then((response) => {
					if (response.ok) {
						alert("Instructor updated successfully!");
						navigate(`/admin/manageInstructors?user_id=${user_id}`);
					} else {
						alert("Failed to update instructor.");
					}
				})
				.catch((error) => {
					console.error("Error while updating instructor:", error);
					alert("An error occurred while updating instructor.");
				});
		}

		if (role === "QA") {
			const { qaFirstName, qaLastName, qaEmail, qaPhone } = qaFormData;

			if (!qaFirstName || !qaLastName || !qaEmail || !qaPhone) {
				alert("Please fill in all required fields.");
				return;
			}

			const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
			if (!emailPattern.test(qaEmail)) {
				alert("Invalid email format. Please enter a valid email address.");
				return;
			}
			const apiURL = API_URL + "admin/qao/update_qao.php";

			const body = new FormData();
			body.append("user_id", edit_id); // passing qa's id
			body.append("first_name", qaFirstName);
			body.append("last_name", qaLastName);
			body.append("email", qaEmail);
			body.append("phone", qaPhone);

			fetch(apiURL, {
				method: "POST",
				body: body,
			})
				.then((response) => {
					if (response.ok) {
						alert("QA updated successfully!");
						navigate(`/admin/manageQA?user_id=${user_id}`);
					} else {
						alert("Failed to update QA.");
					}
				})
				.catch((error) => {
					console.error("Error while updating QA:", error);
					alert("An error occurred while updating QA.");
				});
		}

		if (role === "PC") {
			const { pcFirstName, pcLastName, pcEmail, pcPhone } = pcFormData;

			if (!pcFirstName || !pcLastName || !pcEmail || !pcPhone) {
				alert("Please fill in all required fields.");
				return;
			}

			const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
			if (!emailPattern.test(pcEmail)) {
				alert("Invalid email format. Please enter a valid email address.");
				return;
			}
			const apiURL = API_URL + "admin/pc/update_pc.php";

			const body = new FormData();
			body.append("user_id", edit_id); // passing qa's id
			body.append("first_name", pcFirstName);
			body.append("last_name", pcLastName);
			body.append("email", pcEmail);
			body.append("phone", pcPhone);

			fetch(apiURL, {
				method: "POST",
				body: body,
			})
				.then((response) => {
					if (response.ok) {
						alert("Program Coordinator updated successfully!");
						navigate(`/admin/managePC?user_id=${user_id}`);
					} else {
						alert("Failed to update Program Coordinator.");
					}
				})
				.catch((error) => {
					console.error("Error while updating Program Coordinator:", error);
					alert("An error occurred while updating Program Coordinator.");
				});
		}
	};

	return (
		<>
			<Header role='Admin' header={[["Action Form", ""]]} loggedIn={true} />
			<Navbar nav={nav} />
			<div className='wrapper'>
				<div className='container'>
					<h2 style={{ textAlign: "center" }}>{title}</h2>
					{role === "Student" && ( // Attach the form only to the "Student" role
						<form id='addStudentForm' onSubmit={handleSubmit}>
							<div className='form-group'>
								<label htmlFor='studentFirstName'>Student First Name:</label>
								<input
									type='text'
									id='studentFirstName'
									name='studentFirstName'
									required
									value={formData.studentFirstName}
									onChange={handleChange}
								/>
							</div>
							<div className='form-group'>
								<label htmlFor='studentLastName'>Student Last Name:</label>
								<input
									type='text'
									id='studentLastName'
									name='studentLastName'
									required
									value={formData.studentLastName}
									onChange={handleChange}
								/>
							</div>
							<div className='form-group'>
								<label htmlFor='studentEmail'>Student Email:</label>
								<input
									type='email'
									id='studentEmail'
									name='studentEmail'
									required
									value={formData.studentEmail}
									onChange={handleChange}
								/>
							</div>
							<div className='form-group'>
								<label htmlFor='studentPhone'>Student Contact:</label>
								<input
									type='text'
									id='studentPhone'
									name='studentPhone'
									required
									value={formData.studentPhone}
									onChange={handleChange}
								/>
							</div>

							<div className='btn-container'>
								<button
									className='btn'
									type='submit'
									style={{ backgroundColor: "#982652" }}>
									{action}
								</button>
							</div>
						</form>
					)}

					{role === "Instructor" && ( // Attach the form only to the "Student" role
						<form id='addStudentForm' onSubmit={handleSubmit}>
							<div className='form-group'>
								<label htmlFor='instructorFirstName'>
									Instructor First Name:
								</label>
								<input
									type='text'
									id='instructorFirstName'
									name='instructorFirstName'
									required
									value={instructorFormData.instructorFirstName}
									onChange={handleInstructorChange}
								/>
							</div>
							<div className='form-group'>
								<label htmlFor='instructorLastName'>
									Instructor Last Name:
								</label>
								<input
									type='text'
									id='instructorLastName'
									name='instructorLastName'
									required
									value={instructorFormData.instructorLastName}
									onChange={handleInstructorChange}
								/>
							</div>
							<div className='form-group'>
								<label htmlFor='instructorEmail'>Instructor Email:</label>
								<input
									type='email'
									id='instructorEmail'
									name='instructorEmail'
									required
									value={instructorFormData.instructorEmail}
									onChange={handleInstructorChange}
								/>
							</div>
							<div className='form-group'>
								<label htmlFor='instructorPhone'>Instructor Contact:</label>
								<input
									type='text'
									id='instructorPhone'
									name='instructorPhone'
									required
									value={instructorFormData.instructorPhone}
									onChange={handleInstructorChange}
								/>
							</div>

							<div className='btn-container'>
								<button
									className='btn'
									style={{ backgroundColor: "#982652" }}
									type='submit'>
									{action}
								</button>
							</div>
						</form>
					)}

					{role === "QA" && ( // Attach the form only to the "Student" role
						<form id='addStudentForm' onSubmit={handleSubmit}>
							<div className='form-group'>
								<label htmlFor='qaFirstName'>QA First Name:</label>
								<input
									type='text'
									id='qaFirstName'
									name='qaFirstName'
									required
									value={qaFormData.qaFirstName}
									onChange={handleQAChange}
								/>
							</div>
							<div className='form-group'>
								<label htmlFor='qaLastName'>QA Last Name:</label>
								<input
									type='text'
									id='qaLastName'
									name='qaLastName'
									required
									value={qaFormData.qaLastName}
									onChange={handleQAChange}
								/>
							</div>
							<div className='form-group'>
								<label htmlFor='qaEmail'>QA Email:</label>
								<input
									type='email'
									id='qaEmail'
									name='qaEmail'
									required
									value={qaFormData.qaEmail}
									onChange={handleQAChange}
								/>
							</div>
							<div className='form-group'>
								<label htmlFor='qaPhone'>QA Contact:</label>
								<input
									type='text'
									id='qaPhone'
									name='qaPhone'
									required
									value={qaFormData.qaPhone}
									onChange={handleQAChange}
								/>
							</div>

							<div className='btn-container'>
								<button
									className='btn'
									style={{ backgroundColor: "#982652" }}
									type='submit'>
									{action}
								</button>
							</div>
						</form>
					)}

					{role === "PC" && ( // Attach the form only to the "Student" role
						<form id='addStudentForm' onSubmit={handleSubmit}>
							<div className='form-group'>
								<label htmlFor='pcFirstName'>
									Program Coordinator First Name:
								</label>
								<input
									type='text'
									id='pcFirstName'
									name='pcFirstName'
									required
									value={pcFormData.pcFirstName}
									onChange={handlePCChange}
								/>
							</div>
							<div className='form-group'>
								<label htmlFor='pcLastName'>
									Program Coordinator Last Name:
								</label>
								<input
									type='text'
									id='pcLastName'
									name='pcLastName'
									required
									value={pcFormData.pcLastName}
									onChange={handlePCChange}
								/>
							</div>
							<div className='form-group'>
								<label htmlFor='pcEmail'>Program Coordinator Email:</label>
								<input
									type='email'
									id='pcEmail'
									name='pcEmail'
									required
									value={pcFormData.pcEmail}
									onChange={handlePCChange}
								/>
							</div>
							<div className='form-group'>
								<label htmlFor='pcPhone'>Program Coordinator Contact:</label>
								<input
									type='text'
									id='pcPhone'
									name='pcPhone'
									required
									value={pcFormData.pcPhone}
									onChange={handlePCChange}
								/>
							</div>

							<div className='btn-container'>
								<button
									className='btn'
									type='submit'
									style={{ backgroundColor: "#982652" }}>
									{action}
								</button>
							</div>
						</form>
					)}
				</div>
			</div>
			<Footer />
		</>
	);
};

export default EditAction;
