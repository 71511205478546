/** @format */

import React from "react";
import home1 from "../../images/home1.jpeg";
import home2 from "../../images/home2.jpeg";
import home3 from "../../images/home3.jpeg";
import home4 from "../../images/home4.jpeg";
import Header from "../Header";
import Footer from "../Footer";

const Home = () => {
	const header = [
		["Home", "/"],
		["Services", "/services"],
		["Contact Us", "/contact"],
		["About Us", "/about"],
		["Blog", "https://vxy4152.uta.cloud/wp/"],
	];
	return (
		<>
			<Header role='any' header={header} loggedIn={false} />
			<div className='sectionContainer'>
				<div className='sectionIntro'>
					<h3>
						Eduflex is an EdTech platform that simplifies education management,
						connecting students, instructors, and administrators while upholding
						academic excellence and compliance.
					</h3>
				</div>
			</div>

			<div className='photo-grid-container'>
				<div className='photo-grid'>
					<div className='photo-grid-item'>
						<img src={home1} alt='Photo1' />
					</div>
					<div className='photo-grid-item'>
						<img src={home2} alt='Photo2' />
					</div>
					<div className='photo-grid-item'>
						<img src={home3} alt='Photo3' />
					</div>
					<div className='photo-grid-item'>
						<img src={home4} alt='Photo4' />
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default Home;
