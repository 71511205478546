/** @format */

import React, { useState } from "react";
import "./login.css";
import { useNavigate } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import { API_URL } from "../../constants";

const Login = () => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const navigate = useNavigate();

	const header = [
		["Home", "/"],
		["Services", "/services"],
		["Contact Us", "/contact"],
		["About Us", "/about"],
		["Blog", "https://vxy4152.uta.cloud/wp/"],
	];

	const handleLogin = async (event) => {
		event.preventDefault();

		const formData = new FormData();
		formData.append("email", email);
		formData.append("password", password);

		try {
			const response = await fetch(API_URL + "login.php", {
				method: "POST",
				body: formData,
			});

			if (response.ok) {
				const responseData = await response.json();
				console.log(responseData);
				// alert("Registration successful!");

				if (responseData.role_id === 1) {
					navigate(`/admin?user_id=${responseData.user_id}`);
				} else if (responseData.role_id === 2) {
					navigate(`/student?user_id=${responseData.user_id}`);
				} else if (responseData.role_id === 3) {
					navigate(`/instructor?user_id=${responseData.user_id}`);
				} else if (responseData.role_id === 4) {
					navigate(`/qa?user_id=${responseData.user_id}`);
				} else if (responseData.role_id === 5) {
					navigate(`/pc?user_id=${responseData.user_id}`);
				} else {
					alert("Login failed: " + responseData.message);
				}
			} else {
				const errorData = await response.text();
				console.error("Error while logging in:", errorData);
				alert("An error occurred while logging in.");
			}
		} catch (error) {
			console.error("Error while logging in:", error);
			alert("An error occurred while logging in.");
		}
	};

	return (
		<>
			<Header role='any' header={header} loggedIn={true} />

			<div className='wrapper' style={{ height: "83vh" }}>
				<div className='login-container'>
					<h2>Login</h2>
					<form onSubmit={handleLogin}>
						<div className='form-group'>
							<label htmlFor='email'></label>
							<input
								type='email'
								id='email'
								name='email'
								placeholder='Email'
								required
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>
						</div>
						<div className='form-group'>
							<label htmlFor='password'></label>
							<input
								type='password'
								id='password'
								name='password'
								placeholder='Password'
								required
								value={password}
								onChange={(e) => setPassword(e.target.value)}
							/>
						</div>

						<div className='btn-container'>
							<button type='submit' className='btn'>
								Log in
							</button>
						</div>
					</form>

					<div className='forgot-password'>
						<Link to='/resetPassword'>Forgot password</Link>
					</div>
					<div className='create-account'>
						<button type='button' className='btn'>
							<Link to='/signUp'>Create Account </Link>
						</button>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default Login;
