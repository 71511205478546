/** @format */

import React from "react";
import Header from "../Header";
import Footer from "../Footer";

const Contact = () => {
	const header = [
		["Home", "/"],
		["Services", "/services"],
		["Contact Us", "/contact"],
		["About Us", "/about"],
		["Blog", "https://vxy4152.uta.cloud/wp/"],
	];
	return (
		<>
			<Header role='any' header={header} loggedIn={false} />

			<section className='contactUsMainSection'>
				<div className='textBox'>
					<p className='text-heading'>Contact Us</p>
				</div>
				<div className='textBox'>
					<h2>Please email us at eduflex@uta.edu</h2>
				</div>

				{/* <div className='contactUsForm'>
					<form action='../index.html'>
						<label for='contactName'></label>
						<input type='text' id='contactName' placeholder='Enter your name' />

						<label for='contactEmail'></label>
						<input
							type='text'
							id='contactEmail'
							placeholder='Enter your email'
						/>

						<label for='details'></label>
						<input
							type='text'
							id='details'
							placeholder='Enter details'
							style={{ height: "100px" }}
						/>

						<input type='submit' value='Submit' />
					</form>
				</div> */}
			</section>
			<Footer />
		</>
	);
};

export default Contact;
