/** @format */

import React, { useState } from "react";
import Footer from "../Footer";
import Header from "../Header";
import Navbar from "../Navbar";

const CreateRecommendation = () => {
	const [title, setTitle] = useState("");
	const [course, setCourse] = useState("course1");
	const [description, setDescription] = useState("");
	const [submitted, setSubmitted] = useState(false);

	const handleSubmit = (e) => {
		e.preventDefault();

		if (!title || !description) {
			alert("Please fill in all required fields.");
		} else {
			// Perform form submission logic here (you can replace the alert with your logic)
			alert("Form submitted successfully");
			setSubmitted(true);
		}
	};

	return (
		<>
			<Header role='QA' header={[["Create Feedback", ""]]} loggedIn={true} />
			<Navbar
				nav={[
					["Home", "/qa"],
					["Profile", "/qa/profile"],
					["Messages", "/qa/messages"],
				]}
			/>
			<div className='wrapper'>
				<div className='container' style={{ textAlign: "center" }}>
					<h1>Create Feedback</h1>
					<form className='form' onSubmit={handleSubmit}>
						<label htmlFor='title'>Title:</label>
						<input
							type='text'
							id='title'
							name='title'
							value={title}
							onChange={(e) => setTitle(e.target.value)}
							required
						/>
						<br />
						<br />

						<label htmlFor='course'>Select Course:</label>
						<select
							id='course'
							name='course'
							value={course}
							onChange={(e) => setCourse(e.target.value)}
							required>
							<option value='course1'>Course 1</option>
							<option value='course2'>Course 2</option>
							<option value='course3'>Course 3</option>
						</select>
						<br />
						<br />

						<label htmlFor='description'>Description:</label>
						<textarea
							id='description'
							name='description'
							rows='6'
							cols='26'
							value={description}
							onChange={(e) => setDescription(e.target.value)}
							required></textarea>
						<br />
						<br />

						<button className='btn' type='submit'>
							Submit
						</button>
					</form>

					{submitted && <p>Form submitted successfully</p>}
				</div>
			</div>
			<Footer />
		</>
	);
};

export default CreateRecommendation;
