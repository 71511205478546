/** @format */

import React from "react";

const Footer = () => {
	return (
		<div className='footer'>
			<p>
				&copy; 2023 EduFlex. All rights reserved. | Privacy Policy | Terms of
				Service{" "}
			</p>
		</div>
	);
};

export default Footer;
