/** @format */

import React from "react";
import { Link } from "react-router-dom";
import logo from "../images/logo.png";

const Header = ({ role, header, loggedIn }) => {
	return (
		<>
			<nav className='navBar'>
				<a className='logo' href='index.html'>
					<img src={logo} alt='Logo' />
				</a>
				<ul className='navList'>
					{role === "any" ? (
						header?.map(([itemTitle, itemLink]) => (
							<>
								{itemTitle === "Blog" ? (
									<a href={itemLink} target='_blank' rel='noopener noreferrer'>
										<li>{itemTitle}</li>
									</a>
								) : (
									<Link
										key={itemTitle}
										to={itemLink}
										style={{ textDecoration: "none" }}>
										<li>{itemTitle}</li>
									</Link>
								)}
							</>
						))
					) : (
						<div className='roleTitle'>
							<h1>{header ? header[0][0] : ""}</h1>
						</div>
					)}
				</ul>
				{/* <ul className='navList'>
					<li>
						<a href='index.html' className='active-page'>
							Home
						</a>
					</li>
					<li>
						<a href='homePages/services.html'>Services</a>
					</li>
					<li>
						<a href='homePages/contactUs.html'>Contact Us</a>
					</li>
					<li>
						<a href='homePages/aboutUs.html'>About Us</a>
					</li>
				</ul> */}

				{loggedIn ? (
					role == "any" ? (
						<div></div>
					) : (
						<div className='loginButton'>
							<Link to='/'>Logout</Link>
						</div>
					)
				) : (
					<div className='loginButton'>
						<Link to='/login'>Login</Link>
					</div>
				)}
			</nav>
		</>
	);
};

export default Header;
