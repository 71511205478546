/** @format */

import React, { useState } from "react";
import Header from "../Header";
import "./login.css";
import { API_URL } from "../../constants";
import { useNavigate } from "react-router-dom";
import Footer from "../Footer";

const SignUp = () => {
	const [first_name, setFirstName] = useState("");
	const [last_name, setLastName] = useState("");
	const [phone, setPhone] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [role_id, setRoleId] = useState("1"); // Default role is 1 (Admin)
	const navigate = useNavigate();
	const header = [
		["Home", "/"],
		["Services", "/services"],
		["Contact Us", "/contact"],
		["About Us", "/about"],
		["Blog", "https://vxy4152.uta.cloud/wp/"],
	];

	const handleSignup = async (event) => {
		event.preventDefault();

		const formData = new FormData();
		formData.append("first_name", first_name);
		formData.append("last_name", last_name);
		formData.append("phone", phone);
		formData.append("email", email);
		formData.append("password", password);
		formData.append("role_id", role_id);

		try {
			const response = await fetch(API_URL + "register.php", {
				method: "POST",
				body: formData,
			});

			if (response.ok) {
				const responseData = await response.json();
				if (responseData.success) {
					alert("Registration successful!");

					navigate("/login");
					// if (role_id === "1") {
					// 	navigate("/admin");
					// } else if (role_id === "2") {
					// 	navigate("/student");
					// } else {
					// 	// Handle other roles as needed
					// }
				} else {
					alert("Registration failed: " + responseData.message);
				}
			} else {
				alert("Registration failed. Please try again.");
			}
		} catch (error) {
			console.error("Error while signing up:", error);
			alert("An error occurred while signing up.");
		}
	};
	return (
		<>
			<Header role='any' header={header} loggedIn={true} />

			<div className='wrapper'>
				<div className='signup-container'>
					<h2>Sign Up</h2>
					<p>It’s quick and easy.</p>
					<form id='signup-form' onSubmit={handleSignup}>
						<div className='form-group'>
							<label htmlFor='first_name'></label>
							<input
								type='text'
								id='first_name'
								name='first_name'
								placeholder='First Name'
								required
								value={first_name}
								onChange={(e) => setFirstName(e.target.value)}
							/>
						</div>
						<div className='form-group'>
							<label htmlFor='last_name'></label>
							<input
								type='text'
								id='last_name'
								name='last_name'
								placeholder='Last Name'
								required
								value={last_name}
								onChange={(e) => setLastName(e.target.value)}
							/>
						</div>
						<div className='form-group'>
							<label htmlFor='phone'></label>
							<input
								type='text'
								id='phone'
								name='phone'
								placeholder='Phone Number'
								required
								value={phone}
								onChange={(e) => setPhone(e.target.value)}
							/>
						</div>
						<div className='form-group'>
							<label htmlFor='email'></label>
							<input
								type='email'
								id='email'
								name='email'
								placeholder='Email'
								required
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>
						</div>
						<div className='form-group'>
							<label htmlFor='role'>Select Role:</label>
							<select
								id='role'
								name='role'
								required
								value={role_id}
								onChange={(e) => setRoleId(e.target.value)}>
								<option value='1'>Admin</option>
								<option value='2'>Student</option>
								<option value='3'>Instructor</option>
								<option value='5'>Program Coordinator</option>
								<option value='4'>QA Officer</option>
							</select>
						</div>
						<div className='form-group'>
							<label htmlFor='password'></label>
							<input
								type='password'
								id='password'
								name='password'
								placeholder='Password'
								required
								value={password}
								onChange={(e) => setPassword(e.target.value)}
							/>
						</div>

						<div className='btn-container'>
							<button type='submit' className='btn-signup'>
								Sign Up
							</button>
						</div>
					</form>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default SignUp;
