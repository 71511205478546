/** @format */

import React, { useState } from "react";
import Header from "../Header";
import Navbar from "../Navbar";

const ManagePermissions = () => {
	const [formData, setFormData] = useState({
		studentName: "",
		studentID: "",
		studentEmail: "",
		permissions: [],
	});

	const [isFormSubmitted, setFormSubmitted] = useState(false);

	const handleFormSubmit = (e) => {
		e.preventDefault();
		if (validateForm()) {
			// Form is valid, submit it here
			alert("Form submitted successfully");
			setFormSubmitted(true);
			// You can also submit the form data to your server or perform any other actions here
		} else {
			alert("Form validation failed. Please check the input.");
		}
	};

	const validateForm = () => {
		// Validation logic for email
		const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
		const validEmail = emailPattern.test(formData.studentEmail);

		// Check if all required fields are filled
		const allFieldsFilled = Object.values(formData).every(
			(value) => value !== ""
		);

		return validEmail && allFieldsFilled;
	};

	const handleInputChange = (e) => {
		const { name, value, type, checked } = e.target;

		if (type === "checkbox") {
			// Handle checkbox inputs
			const updatedPermissions = formData.permissions.slice();
			if (checked) {
				updatedPermissions.push(value);
			} else {
				const index = updatedPermissions.indexOf(value);
				if (index !== -1) {
					updatedPermissions.splice(index, 1);
				}
			}
			setFormData({
				...formData,
				permissions: updatedPermissions,
			});
		} else {
			// Handle other input fields
			setFormData({
				...formData,
				[name]: value,
			});
		}
	};

	return (
		<>
			<Header
				role='Admin'
				header={[["Admin Management Console", ""]]}
				loggedIn={true}
			/>
			<Navbar nav={[["Home", "/admin"]]} />
			<div className='wrapper'>
				<div className='container'>
					<h2>Manage Permissions</h2>
					<form id='addStudentForm' onSubmit={handleFormSubmit}>
						<table>
							<tbody>
								<tr>
									<td>
										<label htmlFor='studentName'>User Name:</label>
									</td>
									<td>
										<input
											type='text'
											id='studentName'
											name='studentName'
											required
											value={formData.studentName}
											onChange={handleInputChange}
										/>
									</td>
								</tr>
								<tr>
									<td>
										<label htmlFor='studentID'>User ID:</label>
									</td>
									<td>
										<input
											type='text'
											id='studentID'
											name='studentID'
											required
											value={formData.studentID}
											onChange={handleInputChange}
										/>
									</td>
								</tr>
								<tr>
									<td>
										<label htmlFor='studentEmail'>User Email:</label>
									</td>
									<td>
										<input
											type='email'
											id='studentEmail'
											name='studentEmail'
											required
											value={formData.studentEmail}
											onChange={handleInputChange}
										/>
									</td>
								</tr>
								<tr>
									<td>
										<label htmlFor='permissions'>Permissions:</label>
									</td>
									<td>
										<input
											type='checkbox'
											id='permissions'
											name='permissions'
											value='Read'
											checked={formData.permissions.includes("Read")}
											onChange={handleInputChange}
										/>
										<label htmlFor='permissions'> Read</label>
										<br />
										<input
											type='checkbox'
											id='permissions'
											name='permissions'
											value='modify'
											checked={formData.permissions.includes("modify")}
											onChange={handleInputChange}
										/>
										<label htmlFor='permissions'> modify</label>
										<br />
										<input
											type='checkbox'
											id='permissions'
											name='permissions'
											value='Write'
											checked={formData.permissions.includes("Write")}
											onChange={handleInputChange}
										/>
										<label htmlFor='permissions'> Write</label>
										<br />
									</td>
								</tr>
							</tbody>
						</table>
						<button className='btn' type='submit'>
							Update
						</button>
					</form>
					{isFormSubmitted && <div>Form submitted successfully!</div>}
				</div>
			</div>
		</>
	);
};

export default ManagePermissions;
