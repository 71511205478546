/** @format */

import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../Footer";
import Header from "../Header";
import Navbar from "../Navbar";
import { API_URL } from "../../constants";

const ActionForm = (props) => {
	const navigate = useNavigate();
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const user_id = searchParams.get("user_id");
	const role = location?.state?.role;
	const nav = location?.state?.nav;
	const title = location?.state?.title;
	const action = location?.state?.action;
	const [formData, setFormData] = useState({
		studentFirstName: "",
		studentLastName: "",
		studentEmail: "",
		studentPhone: "",
		studentPassword: "",
	});
	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};
	const [instructorFormData, setInstructorFormData] = useState({
		instructorFirstName: "",
		instructorLastName: "",
		instructorEmail: "",
		instructorPhone: "",
		instructorPassword: "",
	});

	const handleInstructorChange = (e) => {
		const { name, value } = e.target;
		setInstructorFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	const [qaFormData, setQAFormData] = useState({
		qaFirstName: "",
		qaLastName: "",
		qaEmail: "",
		qaPhone: "",
		qaPassword: "",
	});

	const handleQAChange = (e) => {
		const { name, value } = e.target;
		setQAFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	const [pcFormData, setPCFormData] = useState({
		pcFirstName: "",
		pcLastName: "",
		pcEmail: "",
		pcPhone: "",
		pcPassword: "",
	});

	const handlePCChange = (e) => {
		const { name, value } = e.target;
		setPCFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};
	const handleSubmit = (e) => {
		e.preventDefault();

		// Make the API call only if the role is "Student"
		if (role === "Student") {
			const {
				studentFirstName,
				studentLastName,
				studentEmail,
				studentPhone,
				studentPassword,
			} = formData;

			if (
				!studentFirstName ||
				!studentLastName ||
				!studentEmail ||
				!studentPhone ||
				!studentPassword
			) {
				alert("Please fill in all required fields.");
				return;
			}

			const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
			if (!emailPattern.test(studentEmail)) {
				alert("Invalid email format. Please enter a valid email address.");
				return;
			}
			const apiURL = API_URL + "admin/new_student.php";

			const body = new FormData();
			// formData.append("user_id", props.userId);
			body.append("first_name", studentFirstName);
			body.append("last_name", studentLastName);
			body.append("phone", studentPhone);
			body.append("email", studentEmail);
			body.append("password", studentPassword);

			fetch(apiURL, {
				method: "POST",
				body: body,
			})
				.then((response) => {
					if (response.ok) {
						alert("Student added successfully!");
						navigate(`/admin/manageStudents?user_id=${user_id}`);
					} else {
						alert("Failed to add student.");
					}
				})
				.catch((error) => {
					console.error("Error while adding student:", error);
					alert("An error occurred while adding student.");
				});
		}
		// if role is Instructor
		if (role === "Instructor") {
			const {
				instructorFirstName,
				instructorLastName,
				instructorEmail,
				instructorPhone,
				instructorPassword,
			} = instructorFormData;

			if (
				!instructorFirstName ||
				!instructorLastName ||
				!instructorEmail ||
				!instructorPhone ||
				!instructorPassword
			) {
				alert("Please fill in all required fields.");
				return;
			}

			const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
			if (!emailPattern.test(instructorEmail)) {
				alert("Invalid email format. Please enter a valid email address.");
				return;
			}
			const apiURL = API_URL + "admin/instructor/new_instructor.php";

			const body = new FormData();
			// formData.append("user_id", props.userId);
			body.append("first_name", instructorFirstName);
			body.append("last_name", instructorLastName);
			body.append("phone", instructorPhone);
			body.append("email", instructorEmail);
			body.append("password", instructorPassword);

			fetch(apiURL, {
				method: "POST",
				body: body,
			})
				.then((response) => {
					if (response.ok) {
						alert("Instructor added successfully!");
						navigate(`/admin/manageInstructors?user_id=${user_id}`);
					} else {
						alert("Failed to add instructor.");
					}
				})
				.catch((error) => {
					console.error("Error while adding instructor:", error);
					alert("An error occurred while adding instructor.");
				});
		}

		// if role is QA
		if (role === "QA") {
			const { qaFirstName, qaLastName, qaEmail, qaPhone, qaPassword } =
				qaFormData;

			if (!qaFirstName || !qaLastName || !qaEmail || !qaPhone || !qaPassword) {
				alert("Please fill in all required fields.");
				return;
			}

			const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
			if (!emailPattern.test(qaEmail)) {
				alert("Invalid email format. Please enter a valid email address.");
				return;
			}
			const apiURL = API_URL + "admin/qao/new_qao.php";

			const body = new FormData();
			// formData.append("user_id", props.userId);
			body.append("first_name", qaFirstName);
			body.append("last_name", qaLastName);
			body.append("phone", qaPhone);
			body.append("email", qaEmail);
			body.append("password", qaPassword);

			fetch(apiURL, {
				method: "POST",
				body: body,
			})
				.then((response) => {
					if (response.ok) {
						alert("QAO added successfully!");
						navigate(`/admin/manageQA?user_id=${user_id}`);
					} else {
						alert("Failed to add QAO.");
					}
				})
				.catch((error) => {
					console.error("Error while adding QAO:", error);
					alert("An error occurred while adding QAO.");
				});
		}

		// if role is PC
		if (role === "PC") {
			const { pcFirstName, pcLastName, pcEmail, pcPhone, pcPassword } =
				pcFormData;

			if (!pcFirstName || !pcLastName || !pcEmail || !pcPhone || !pcPassword) {
				alert("Please fill in all required fields.");
				return;
			}

			const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
			if (!emailPattern.test(pcEmail)) {
				alert("Invalid email format. Please enter a valid email address.");
				return;
			}
			const apiURL = API_URL + "admin/pc/new_pc.php";

			const body = new FormData();
			// formData.append("user_id", props.userId);
			body.append("first_name", pcFirstName);
			body.append("last_name", pcLastName);
			body.append("phone", pcPhone);
			body.append("email", pcEmail);
			body.append("password", pcPassword);

			fetch(apiURL, {
				method: "POST",
				body: body,
			})
				.then((response) => {
					if (response.ok) {
						alert("Program Coordinator added successfully!");
						navigate(`/admin/managePC?user_id=${user_id}`);
					} else {
						alert("Failed to add Program Coordinator.");
					}
				})
				.catch((error) => {
					console.error("Error while adding Program Coordinator :", error);
					alert("An error occurred while adding Program Coordinator.");
				});
		}
	};

	return (
		<>
			<Header role='Admin' header={[["Action Form", ""]]} loggedIn={true} />
			<Navbar nav={nav} />
			<div className='wrapper'>
				<div className='container'>
					<h2 style={{ textAlign: "center" }}>{title}</h2>
					{role === "Student" && ( // Attach the form only to the "Student" role
						<form id='addStudentForm' onSubmit={handleSubmit}>
							<div className='form-group'>
								<label htmlFor='studentFirstName'>Student First Name:</label>
								<input
									type='text'
									id='studentFirstName'
									name='studentFirstName'
									required
									value={formData.studentFirstName}
									onChange={handleChange}
								/>
							</div>
							<div className='form-group'>
								<label htmlFor='studentLastName'>Student Last Name:</label>
								<input
									type='text'
									id='studentLastName'
									name='studentLastName'
									required
									value={formData.studentLastName}
									onChange={handleChange}
								/>
							</div>
							<div className='form-group'>
								<label htmlFor='studentEmail'>Student Email:</label>
								<input
									type='email'
									id='studentEmail'
									name='studentEmail'
									required
									value={formData.studentEmail}
									onChange={handleChange}
								/>
							</div>
							<div className='form-group'>
								<label htmlFor='studentPhone'>Student Contact:</label>
								<input
									type='text'
									id='studentPhone'
									name='studentPhone'
									required
									value={formData.studentPhone}
									onChange={handleChange}
								/>
							</div>
							<div className='form-group'>
								<label htmlFor='studentPassword'>Password:</label>
								<input
									type='password'
									id='studentPassword'
									name='studentPassword'
									required
									value={formData.studentPassword}
									onChange={handleChange}
								/>
							</div>
							<div className='btn-container'>
								<button className='btn' type='submit'>
									{action}
								</button>
							</div>
						</form>
					)}
					{/* if role is instructor */}
					{role === "Instructor" && ( // Attach the form only to the "Student" role
						<form id='addStudentForm' onSubmit={handleSubmit}>
							<div className='form-group'>
								<label htmlFor='instructorFirstName'>
									Instructor First Name:
								</label>
								<input
									type='text'
									id='instructorFirstName'
									name='instructorFirstName'
									required
									value={instructorFormData.instructorFirstName}
									onChange={handleInstructorChange}
								/>
							</div>
							<div className='form-group'>
								<label htmlFor='instructorLastName'>
									Instructor Last Name:
								</label>
								<input
									type='text'
									id='instructorLastName'
									name='instructorLastName'
									required
									value={instructorFormData.instructorLastName}
									onChange={handleInstructorChange}
								/>
							</div>
							<div className='form-group'>
								<label htmlFor='instructorEmail'>Instructor Email:</label>
								<input
									type='email'
									id='instructorEmail'
									name='instructorEmail'
									required
									value={instructorFormData.instructorEmail}
									onChange={handleInstructorChange}
								/>
							</div>
							<div className='form-group'>
								<label htmlFor='instructorPhone'>Instructor Contact:</label>
								<input
									type='text'
									id='instructorPhone'
									name='instructorPhone'
									required
									value={instructorFormData.instructorPhone}
									onChange={handleInstructorChange}
								/>
							</div>
							<div className='form-group'>
								<label htmlFor='instructorPassword'>Password:</label>
								<input
									type='password'
									id='instructorPassword'
									name='instructorPassword'
									required
									value={instructorFormData.instructorPassword}
									onChange={handleInstructorChange}
								/>
							</div>
							<div className='btn-container'>
								<button className='btn' type='submit'>
									{action}
								</button>
							</div>
						</form>
					)}
					{/* //QA */}
					{role === "QA" && ( // Attach the form only to the "Student" role
						<form id='addStudentForm' onSubmit={handleSubmit}>
							<div className='form-group'>
								<label htmlFor='qaFirstName'>QA First Name:</label>
								<input
									type='text'
									id='qaFirstName'
									name='qaFirstName'
									required
									value={qaFormData.qaFirstName}
									onChange={handleQAChange}
								/>
							</div>
							<div className='form-group'>
								<label htmlFor='qaLastName'>QA Last Name:</label>
								<input
									type='text'
									id='qaLastName'
									name='qaLastName'
									required
									value={qaFormData.qaLastName}
									onChange={handleQAChange}
								/>
							</div>
							<div className='form-group'>
								<label htmlFor='qaEmail'>QA Email:</label>
								<input
									type='email'
									id='qaEmail'
									name='qaEmail'
									required
									value={qaFormData.qaEmail}
									onChange={handleQAChange}
								/>
							</div>
							<div className='form-group'>
								<label htmlFor='qaPhone'>QA Contact:</label>
								<input
									type='text'
									id='qaPhone'
									name='qaPhone'
									required
									value={qaFormData.qaPhone}
									onChange={handleQAChange}
								/>
							</div>
							<div className='form-group'>
								<label htmlFor='qaPassword'>Password:</label>
								<input
									type='password'
									id='qaPassword'
									name='qaPassword'
									required
									value={qaFormData.qaPassword}
									onChange={handleQAChange}
								/>
							</div>
							<div className='btn-container'>
								<button className='btn' type='submit'>
									{action}
								</button>
							</div>
						</form>
					)}

					{/* //PC */}
					{role === "PC" && ( // Attach the form only to the "Student" role
						<form id='addStudentForm' onSubmit={handleSubmit}>
							<div className='form-group'>
								<label htmlFor='pcFirstName'>
									Program Coordinator First Name:
								</label>
								<input
									type='text'
									id='pcFirstName'
									name='pcFirstName'
									required
									value={pcFormData.pcFirstName}
									onChange={handlePCChange}
								/>
							</div>
							<div className='form-group'>
								<label htmlFor='pcLastName'>
									Program Coordinator Last Name:
								</label>
								<input
									type='text'
									id='pcLastName'
									name='pcLastName'
									required
									value={pcFormData.pcLastName}
									onChange={handlePCChange}
								/>
							</div>
							<div className='form-group'>
								<label htmlFor='pcEmail'>Program Coordinator Email:</label>
								<input
									type='email'
									id='pcEmail'
									name='pcEmail'
									required
									value={pcFormData.pcEmail}
									onChange={handlePCChange}
								/>
							</div>
							<div className='form-group'>
								<label htmlFor='pcPhone'>Program Coordinator Contact:</label>
								<input
									type='text'
									id='pcPhone'
									name='pcPhone'
									required
									value={pcFormData.pcPhone}
									onChange={handlePCChange}
								/>
							</div>
							<div className='form-group'>
								<label htmlFor='pcPassword'>Password:</label>
								<input
									type='password'
									id='pcPassword'
									name='pcPassword'
									required
									value={pcFormData.pcPassword}
									onChange={handlePCChange}
								/>
							</div>
							<div className='btn-container'>
								<button className='btn' type='submit'>
									{action}
								</button>
							</div>
						</form>
					)}
				</div>
			</div>
			<Footer />
		</>
	);
};

export default ActionForm;
