/** @format */

import React, { useState, useEffect } from "react";
import Header from "./Header";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { useLocation } from "react-router-dom";
import { API_URL } from "../constants";
import "./messages.css";

const Messages = ({ role }) => {
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const user_id = searchParams.get("user_id");
	const [users, setUsers] = useState([]);
	const [selectedUser, setSelectedUser] = useState(null);
	const [message, setMessage] = useState("");
	const [conversations, setConversations] = useState([]);
	const handleClick = (user) => {
		setSelectedUser(user);
	};
	const sendMessage = async () => {
		const endpoint = `${API_URL}chat/new_message.php`;
		const formData = new FormData();
		formData.append("sender_id", user_id);
		formData.append("receiver_id", selectedUser.id);
		formData.append("content", message);

		try {
			const response = await fetch(endpoint, {
				method: "POST",
				body: formData,
			});

			if (response.ok) {
				const responseData = await response.json();
				console.log(responseData);
				setMessage("");
				// alert("Registration successful!");
			} else {
				const errorData = await response.text();
				console.error("Error while sending message:", errorData);
				alert("An error occurred while sending message.");
			}
		} catch (error) {
			console.error("Error while sending message:", error);
			alert("An error occurred while sending message.");
		}
	};
	useEffect(() => {
		setConversations([]);
		if (selectedUser) {
			const timer = setInterval(() => {
				const endpoint = `${API_URL}chat/get_conversation.php?user1_id=${user_id}&user2_id=${selectedUser.id}`;
				fetch(endpoint)
					.then((response) => response.json())
					.then((data) => {
						if (data.success) {
							console.log(data);
							setConversations(data.messages);
							console.log(conversations);
						} else {
							console.error("Failed to fetch user data.");
						}
					});
			}, 3000);
			return () => {
				clearInterval(timer);
			};
		}
	}, [selectedUser]);

	useEffect(() => {
		const endpoint = `${API_URL}chat/get_all_users.php?user_id=${user_id}`;
		fetch(endpoint)
			.then((response) => response.json())
			.then((data) => {
				if (data.success) {
					setUsers(data.users);
					// console.log(users);
				} else {
					console.error("Failed to fetch user data.");
				}
			});
	}, []);
	return (
		<>
			<Header role={role} header={[["Chat Messages", ""]]} loggedIn={true} />
			<Navbar nav={[["Home", `/${role.toLowerCase()}?user_id=${user_id}`]]} />

			<div className='wrapper'>
				<div className='messaging-container'>
					<div className='side-panel'>
						<span>
							<h3>Select User from below list to chat</h3>
						</span>
						{users?.map((user) => (
							<button
								key={user.id}
								className='side-panel-btn'
								onClick={() => handleClick(user)}>
								<div className='conversation' style={{ margin: "0px" }}>
									<h2 style={{ margin: "5px" }}>
										{user.first_name} {user.last_name}
									</h2>
									<p style={{ margin: "5px" }}>{user.role_name}</p>
								</div>
							</button>
						))}
					</div>
					{selectedUser && (
						<div className='main-window'>
							<div className='header'>
								<h1>
									{selectedUser.first_name} {selectedUser.last_name}
								</h1>
							</div>
							{conversations?.map((conversation) => (
								<div className='message-list'>
									{user_id == conversation.receiver_id ? (
										<div className='message from-friend'>
											<p>{conversation.content}</p>
										</div>
									) : (
										<div className='message from-me'>
											<p>{conversation.content}</p>
										</div>
									)}
								</div>
							))}
							<div className='message-input'>
								<label>
									<textarea
										onChange={(e) => setMessage(e.target.value)}
										value={message}
										placeholder='Type your message...'></textarea>
								</label>
								<button type='button' onClick={sendMessage}>
									Send
								</button>
							</div>
						</div>
					)}
				</div>
			</div>
			<Footer />
		</>
	);
};

export default Messages;
