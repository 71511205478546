/** @format */

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./login.css";
import Header from "../Header";
import Footer from "../Footer";
import { API_URL } from "../../constants";

const ResetPassword = () => {
	const [email, setEmail] = useState("");
	const [resetMessage, setResetMessage] = useState("");
	const navigate = useNavigate();

	const header = [
		["Home", "/"],
		["Services", "/services"],
		["Contact Us", "/contact"],
		["About Us", "/about"],
		["Blog", "https://vxy4152.uta.cloud/wp/"],
	];

	const handleResetPassword = async (event) => {
		event.preventDefault();

		const formData = new FormData();
		formData.append("email", email);

		try {
			const response = await fetch(API_URL + "forgot_password.php", {
				method: "POST",
				body: formData,
			});

			if (response.ok) {
				const responseData = await response.json();
				if (responseData.success) {
					setResetMessage("Password reset link sent to your email.");
					setTimeout(() => {
						navigate("/login");
					}, 3000); // Automatically navigate to login after 3 seconds
				} else {
					setResetMessage("Failed to send reset link. Please try again later.");
				}
			} else {
				setResetMessage("Failed to send reset link. Please try again later.");
			}
		} catch (error) {
			console.error("Error while requesting password reset:", error);
			setResetMessage("An error occurred. Please try again later.");
		}
	};

	return (
		<>
			<Header role='any' header={header} loggedIn={true} />

			<div className='wrapper'>
				<div className='forgot-password-container'>
					<h2>Find your account</h2>
					<p>Please enter your email to search for your account.</p>
					<form onSubmit={handleResetPassword}>
						<div className='form-group'>
							<input
								type='email'
								id='email'
								name='email'
								placeholder='Email'
								required
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>
						</div>
						<div className='btn-container'>
							<button type='button' className='btn-cancel'>
								<Link to='/login'>Cancel</Link>
							</button>
							<button type='submit' className='btn-search'>
								Send
							</button>
						</div>
					</form>
					{resetMessage && <p className='reset-message'>{resetMessage}</p>}
				</div>
			</div>
			<Footer />
		</>
	);
};

export default ResetPassword;
