/** @format */

import React from "react";
import Footer from "../Footer";
import Header from "../Header";

const AboutUs = () => {
	const header = [
		["Home", "/"],
		["Services", "/services"],
		["Contact Us", "/contact"],
		["About Us", "/about"],
		["Blog", "https://vxy4152.uta.cloud/wp/"],
	];
	return (
		<>
			<Header role='any' header={header} loggedIn={false} />
			<div style={{ height: "92vh" }}>
				<div className='sectionContainer'>
					<div className='sectionIntro'>
						<h3>
							We are a team of 5 energetic Graduate students from the University
							of Texas at Arlington, building the EduFlex Platform{" "}
						</h3>
					</div>
					<h2 style={{ "text-align": "center" }}>Our Team</h2>
				</div>

				<div className='row-container-aboutus'>
					<div className='row'>
						<div className='column'>
							<div className='card'>
								<div className='home-container'>
									<h2>Vivek Yelethotadahalli Srinivas</h2>
									<p className='title'>CEO & Founder</p>
									<p>
										Strategic visionary and top executive responsible for
										overall company direction and leadership.
									</p>
									<p>vivek@gmail.com</p>
									<p>
										<button className='button'>Contact</button>
									</p>
								</div>
							</div>
						</div>

						<div className='column'>
							<div className='card'>
								<div className='home-container'>
									<h2>Pallavi Sunkara</h2>
									<p className='title'>Co-founder</p>
									<p>
										Entrepreneurial partner involved in shaping the company's
										mission and strategy.
									</p>
									<p>pallavi@gmail.com</p>
									<p>
										<button className='button'>Contact</button>
									</p>
								</div>
							</div>
						</div>

						<div className='column'>
							<div className='card'>
								<div className='home-container'>
									<h2>Sai Manideep Sanganabhatla</h2>
									<p className='title'>Technical Lead</p>
									<p>
										Lead developer responsible for guiding and overseeing
										technical projects and teams.
									</p>
									<p>manideep@gmail.com</p>
									<p>
										<button className='button'>Contact</button>
									</p>
								</div>
							</div>
						</div>
					</div>

					<div className='row'>
						<div className='column'>
							<div className='card'>
								<div className='home-container'>
									<h2>Karthik Rama Raju Perearla</h2>
									<p className='title'>Lead Architect</p>
									<p>
										Principal designer and planner of the system or software
										architecture.
									</p>
									<p>karthik@gmail.com</p>
									<p>
										<button className='button'>Contact</button>
									</p>
								</div>
							</div>
						</div>

						<div className='column'>
							<div className='card'>
								<div className='home-container'>
									<h2>Harsha Vardhan Nunna</h2>
									<p className='title'>Founding Engineer</p>
									<p>
										Pioneering developer who plays a critical role in building
										the initial product or technology.
									</p>
									<p>
										<button className='button'>Contact</button>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Footer />
		</>
	);
};

export default AboutUs;
